import React from 'react';
import PropTypes from 'prop-types';
import { Button, FlexGrid, Header, Text } from '@gasbuddy/react-components';
import Container from '../Container';
import Panel from '../Panel';
import PwGBCardImage from '../PwGBCardImage';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function PaymentSubmitted({ consumerHost }) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_PERIODIC_PAYMENT_SETTLED);

  return (
    <Container sidebar={false}>
      <Panel>
        <PwGBCardImage tilted />
        <Header as="h2">Thanks for your payment!</Header>
        <Text as="p">Your Pay with GasBuddy+™ payment has been submitted for processing.</Text>
        <br />
        <br />
        <FlexGrid>
          <FlexGrid.Column tablet={6} desktop={3}>
            <Button
              as="a"
              href={`//${consumerHost}/account/savings`}
              primary
              fluid
            >
              Continue
            </Button>
          </FlexGrid.Column>
        </FlexGrid>
      </Panel>
    </Container>
  );
}

PaymentSubmitted.propTypes = {
  consumerHost: PropTypes.string,
};

PaymentSubmitted.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
