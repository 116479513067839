import { Action } from './actions';

const defaultState = {
  isLoading: false,
  error: undefined,
  instruments: [],
  features: {
    features: [],
  },
  status: {},
};

export default function walletReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.ShippingAddress.UpdateCompleted:
    case Action.License.LicenseSaveCompleted:
    case Action.Ship.ShipCardCompleted:
    case Action.Bank.InstrumentCreateCompleted:
    case Action.Microdeposits.ConfirmCompleted:
    case Action.Activation.ActivateCardCompleted:
    case Action.Membership.UpgradeCompleted:
    case Action.Activation.ActivateMigratingCardCompleted:
      return {
        ...state,
        ...action.payload.wallet,
      };

    case Action.Esign.SaveConsentPending:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case Action.Esign.SaveConsentFailed:
      return {
        ...state,
        isLoading: true,
        error: 'There was an error saving your consent. Please try again.',
      };

    case Action.Esign.SaveConsentCompleted:
      return {
        ...state,
        status: {
          ...state.status,
          esign: action.payload.esigned,
        },
      };

    case Action.Billing.UpdateCurrentPaymentOptionCompleted:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload?.esigned && { esign: action.payload.esigned },
        },
      };

    default:
      return state;
  }
}
