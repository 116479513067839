import React from 'react';
import PropTypes from 'prop-types';
import { Button, FlexGrid, Header, Text } from '@gasbuddy/react-components';
import SupportLink from '../../components/SupportLink';
import Panel from '../../components/Panel';
import Container from '../../components/Container';

export default function ErrorSwitchingCard({ consumerHost }) {
  return (
    <Container sidebar={false}>
      <Panel>
        <Header as="h2">Oops! Something went wrong.</Header>
        <Text as="p" elongated size="lg" uppercase>
          Sorry, we were unable to order your new card.
        </Text>
        <Text as="p">
          You can try to order the new card again by going to the My Savings page.
        </Text>
        <Text as="p">
          Please contact support if you continue to see the issue.
        </Text>
        <br />
        <br />
        <FlexGrid>
          <FlexGrid.Column tablet={6} desktop={3}>
            <Button
              as="a"
              href={`//${consumerHost}/account/savings`}
              primary
              fluid
            >
              Back to Savings
            </Button>
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6} desktopOffset={1} desktop={3}>
            <SupportLink uppercase>Contact Support</SupportLink>
          </FlexGrid.Column>
        </FlexGrid>
      </Panel>
    </Container>
  );
}

ErrorSwitchingCard.propTypes = {
  consumerHost: PropTypes.string,
};

ErrorSwitchingCard.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
