import { Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import CharityOptions from '../../constants/charityOptions';
import useTracking from '../../hooks/useTracking';
import { CharityIdPropType } from '../../prop-types/gasback';
import CardChoiceConfirmation from '../CardChoiceConfirmation';
import GasBackTransferStep from '../GasBackTransferStep';

export default function GasBackCharityTransferSuccess({
  charityId: chosenCharityId,
  consumerHost,
  donationAmount,
  recipientEmail,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAYPLUS_REDEEM_CHARITY_COMPLETE);

  const handleReset = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_CHARITY_COMPLETE_CONTINUE_CLICKED);
  }, [trackEvent]);

  const chosenCharity = useMemo(() => CharityOptions.find(o => o.id === chosenCharityId), [chosenCharityId]);

  if (!chosenCharity) {
    return (
      <Redirect to="/gasback/transfer/charity" />
    );
  }

  return (
    <GasBackTransferStep
      context={{ screen: ANALYTICS_SCREENS.PAYPLUS_REDEEM_CHARITY_COMPLETE }}
      title="Thank you! Your donation will help feed those in need"
      subtitle={`We will send you a confirmation email. On behalf of everyone here at GasBuddy, thank you for your donation to ${chosenCharity.name}.`}
      smallHeader
    >
      <CardChoiceConfirmation
        imageUrl={chosenCharity.imageUrl}
        cardVendorName={chosenCharity.name}
      >
        <input type="hidden" name="charityId" value={chosenCharity.id} />
        <input type="hidden" name="donationAmount" value={donationAmount} />
        <Text as="p">Donation Amount</Text>
        <Text as="p" bold>{formatPrice(donationAmount)}</Text>
        <br />
        <Text as="p">Your confirmation email will be sent to</Text>
        <Text as="p" bold>{recipientEmail}</Text>
        <br />
        <CardChoiceConfirmation.Terms
          title="Donation details"
          content={chosenCharity.donationDescription}
        />
        <br />
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            as="a"
            fluid
            href={`//${consumerHost}/account/savings`}
            onClick={handleReset}
            primary
          >
            Done
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </CardChoiceConfirmation>
    </GasBackTransferStep>
  );
}

GasBackCharityTransferSuccess.propTypes = {
  charityId: CharityIdPropType.isRequired,
  consumerHost: PropTypes.string,
  donationAmount: PropTypes.string.isRequired,
  recipientEmail: PropTypes.string.isRequired,
};

GasBackCharityTransferSuccess.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
