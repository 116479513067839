import React, { useEffect } from 'react';
import { Button, Header, Loader, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import Container from '../Container';
import Panel from '../Panel';
import ManualPaymentOption from '../PaymentOptions/ManualPaymentOption';
import AutopayPaymentOption from '../PaymentOptions/AutopayPaymentOption';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import PwGBCardImage from '../PwGBCardImage';
import isMembershipProgram from '../../../lib/utils/isMembershipProgram';

export default function PaymentOptionUpdateSuccess({
  consumerHost,
  paymentOption,
  payProgram,
  isLoading,
  error,
  fetchCurrentPaymentOption,
}) {
  const isMembershipUser = isMembershipProgram(payProgram);
  useEffect(() => {
    if (!isLoading && !error && !paymentOption) {
      fetchCurrentPaymentOption();
    }
  }, [isLoading, error, paymentOption, fetchCurrentPaymentOption]);

  let content;
  if (isLoading) {
    content = (
      <Loader data-testid="loader" size="lg" />
    );
  } else if (error) {
    content = (
      <React.Fragment>
        <PwGBCardImage tilted />
        <br />
        <Text color="orange">{error}</Text>
        <br />
        <br />
        <Button
          primary
          wide
          as="a"
          href={`//${consumerHost}/account/savings`}
        >
          Continue
        </Button>
      </React.Fragment>
    );
  } else if (paymentOption) {
    content = (
      <React.Fragment>
        <PwGBCardImage tilted />
        <Header as="h2">Your payment option has been updated!</Header>
        {paymentOption === 'autopay' ? (
          <AutopayPaymentOption isMembershipUser={isMembershipUser} />
        ) : (
          <ManualPaymentOption isMembershipUser={isMembershipUser} />
        )}
        <br />
        <br />
        <Button
          primary
          wide
          as="a"
          href={`//${consumerHost}/account/savings`}
        >
          Continue
        </Button>
      </React.Fragment>
    );
  }


  return (
    <Container sidebar={false}>
      <Panel>
        {content}
      </Panel>
    </Container>
  );
}

PaymentOptionUpdateSuccess.propTypes = {
  consumerHost: PropTypes.string,
  paymentOption: PaymentOptionPropType,
  payProgram: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  fetchCurrentPaymentOption: PropTypes.func,
};

PaymentOptionUpdateSuccess.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  paymentOption: undefined,
  payProgram: undefined,
  isLoading: false,
  error: undefined,
  fetchCurrentPaymentOption: () => {},
};
