import React from 'react';
import { Button, FlexGrid, Form, Header, Text } from '@gasbuddy/react-components';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import Container from '../Container';
import Panel from '../Panel';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import PwGBCardImage from '../PwGBCardImage';
import styles from './PaymentError.module.css';
import DownloadApp from '../DownloadApp/DownloadApp';
import SupportLink from '../SupportLink';

const cx = classnames.bind(styles);

export default function PaymentError({ paymentInProgress }) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_PERIODIC_PAYMENT_ATTEMPT_FAILED);

  if (paymentInProgress) {
    return (
      <Redirect to="/enroll" />
    );
  }

  return (
    <Container sidebar={false}>
      <Panel>
        <PwGBCardImage tilted />
        <Header as="h2">Payment attempt failed</Header>
        <Text as="p" color="orange">
          We encountered an error processing your payment.
          Please try again or reach out to support for help.
        </Text>
        <br />
        <br />
        <FlexGrid>
          <FlexGrid.Column tablet={6} desktop={3}>
            <Form method="post" action="/payment-options/pay-now">
              <Button
                type="submit"
                primary
                warning
                fluid
              >
                Try Again
              </Button>
            </Form>
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6} desktopOffset={1} desktop={3}>
            <SupportLink>Contact Support</SupportLink>
          </FlexGrid.Column>
        </FlexGrid>
        <br />
        <br />
        <Text as="p" className={cx('fundingMessage')}>
          Please ensure your bank account has enough funds available and attempt to withdraw again.
          For additional ways to settle your balance, use the GasBuddy app.
          <br />
          <br />
          <DownloadApp />
        </Text>
      </Panel>
    </Container>
  );
}

PaymentError.propTypes = {
  paymentInProgress: PropTypes.bool,
};

PaymentError.defaultProps = {
  paymentInProgress: false,
};
