import React, { Fragment } from 'react';

const commonPoints = [
  {
    key: 'renewal',
    description: 'Your subscription renews automatically and you are free to cancel at anytime.',
  },
  {
    key: 'requirement',
    description: 'Subscription plans require you to remain on the Auto Payment method.',
  },
];

export const PlusPoints = [
  {
    key: 'discount',
    description: 'Fuel discounts go into effect once you receive and activate your card.',
  },
  {
    key: 'monthlyLimit',
    description: 'The monthly gallon limit begins on the day you activate your card and resets the same date the following month.',
  },
  ...commonPoints,
];

/* eslint-disable max-len */
export const PremiumPoints = [
  {
    key: 'maxDiscount',
    description: <Fragment><b>Save up to 47¢/gal</b> with an activated Deal Alert.</Fragment>,
  },
  {
    key: 'regularDiscount',
    description: <Fragment><b>Save 20¢/gal guaranteed</b> and <b>22¢/gal</b> at select stations (up to 50 gallons per month), then 5¢/gal thereafter.</Fragment>,
  },
  {
    key: 'monthlyLimit',
    description: <Fragment>The monthly gallon limit begins on the day you activate your card and resets the same date the following month.</Fragment>,
  },
  {
    key: 'roadside',
    description: <Fragment><b>Enjoy 24-hour roadside assistance</b> provided by Allstate, up to 2 events per year. Roadside assistance goes into effect 72 hours after purchase date.</Fragment>,
  },
  {
    key: 'extraDiscount',
    description: <Fragment><b>Earn an extra 3¢/gal</b> for every $25 you spend in the c-store. </Fragment>,
  },
  ...commonPoints,
];
/* eslint-enable max-len */

export const PassengerVehicleDefinition = 'A passenger vehicle is a vehicle designed to carry no more than nine passengers, including the driver, and does not include motorcycles or RVs.';
export const PassengerVehicleDetail = 'If you do not drive a passenger vehicle, you can not purchase premium membership.';
