import { connect } from 'react-redux';
import Screen from './MigratingCardActivationComplete';

function mapStateToProps({ config }) {
  return {
    consumerHost: config.consumerHost,
  };
}

const MigratingCardActivationComplete = connect(mapStateToProps)(Screen);
export default MigratingCardActivationComplete;
