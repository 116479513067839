import React from 'react';
import { Container as RCContainer, FlexGrid, Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './Container.module.css';
import Footer from '../Footer';

const cx = classnames.bind(styles);

// TODO: When GradientContainer is fully fleshed out, we shouldn't need this component anymore
export default function Container({ children, sidebar, ...rest }) {
  const { className, ...props } = rest;

  return (
    <RCContainer {...props} className={cx('container', { noSidebar: !sidebar }, className)}>
      <FlexGrid>
        <FlexGrid.Column tablet={sidebar ? 10 : 12} tabletOffset={sidebar ? 2 : 0}>
          <div className={cx('logo')}>
            <Image
              className={cx('logoImage')}
              alt="Pay with GasBuddy+™ Logo"
              src="https://static.gasbuddy.com/web/pay/svg/pwgb-plus-logo.svg"
            />
          </div>
        </FlexGrid.Column>
        {!!sidebar && (
          <FlexGrid.Column className={cx('statusContainer')} tablet={3} desktop={2}>
            {sidebar}
          </FlexGrid.Column>
        )}
        <FlexGrid.Column tablet={sidebar ? 9 : 11} desktop={sidebar ? 10 : 12}>
          {children}
        </FlexGrid.Column>
        <FlexGrid.Column tablet={sidebar ? 9 : 11} tabletOffset={sidebar ? 3 : 1} desktop={sidebar ? 8 : 10}>
          <Footer />
        </FlexGrid.Column>
      </FlexGrid>
    </RCContainer>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
};

Container.defaultProps = {
  children: undefined,
  sidebar: undefined,
};
