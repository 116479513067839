import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './PaymentOptions.module.css';

const cx = classnames.bind(styles);

export default function ManualPaymentOption({ isMembershipUser }) {
  return (
    <React.Fragment>
      <Text className={cx('label')}>Manual Bill Pay</Text>
      {isMembershipUser && (
        <Fragment>
          <Text as="p" uppercase className={cx('highlightNote')}>
            <b>Not available to Premium or Plus members</b>
          </Text>
        </Fragment>
      )}
      <Text as="p" className={cx('periodicPaymentBenefits')}>
        Fuel savings on fill-ups and c-store purchases are only applicable to cardholders who opt in to Auto Pay<br />
        <strong>$50 daily</strong> spending limit (per 24 hours)<br />
        <strong>$50 weekly</strong> spending limit (per 7 days)<br />
        Pay balance manually each week<br />
        Balance due 7 days after statement is sent
      </Text>
    </React.Fragment>
  );
}

ManualPaymentOption.propTypes = {
  isMembershipUser: PropTypes.bool,
};

ManualPaymentOption.defaultProps = {
  isMembershipUser: false,
};
