// Existing PwGB customers are assigned an instrument of `private_card` type with provider set as `gasbuddy`
// The new customers being enrolled with Fleetcor program / weekly payments are assigned an instrument
// of `private_card` type with provider set as `pdi`
const isPwGBCard = instrument => ['gasbuddy', 'pdi'].includes(instrument.provider_name);
const isPayCard = instrument => instrument.instrument_type === 'private_card' && isPwGBCard(instrument);
const isCreditCard = instrument => instrument.instrument_type === 'network_card' && isPwGBCard(instrument);

export const getPayCard = (instruments = []) => instruments.find(isPayCard);

export const hasPayCard = (instruments = []) => instruments.some(isPayCard);

export const getCreditCard = (instruments = []) => instruments.find(isCreditCard);

export const hasCreditCard = (instruments = []) => instruments.some(isCreditCard);

export const getPwGBCard = (instruments = []) => instruments.find(({ provider_name: provider }) => provider === 'gasbuddy');

export const getPwGBPlusCard = (instruments = []) => instruments.find(({ provider_name: provider }) => provider === 'pdi');
