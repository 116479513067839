import { Form, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import { PlusPoints, PremiumPoints } from '../../constants/membership';
import PayPrograms from '../../constants/payPrograms';
import useTracking from '../../hooks/useTracking';
import ProgramPropType from '../../prop-types/program';
import FormActionButtons from '../FormActionButtons';
import MembershipOption from '../MembershipOption';
import PassengerVehicleCheckbox from '../PassengerVehicleCheckbox';

export default function MembershipBilling({
  offeringId: initialOfferingId,
  onSubmit,
  options,
  program,
}) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_PREMIUM_BILLING_OPTIONS);

  const [offeringId, setOfferingId] = useState(initialOfferingId);
  const [passengerVehicle, setPassengerVehicle] = useState(false);
  const selectedOffer = options.find(o => o.plan_offering_id === offeringId);
  const hasRoadside = selectedOffer?.has_roadside;

  const handleOfferChange = useCallback((id) => {
    setOfferingId(id);
  }, []);

  const handlePassengerVehicleChange = useCallback((e) => {
    const { checked } = e.target;
    setPassengerVehicle(checked);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    onSubmit({ offeringId });
  }, [offeringId, onSubmit]);

  useEffect(() => {
    setOfferingId(initialOfferingId);
  }, [initialOfferingId]);

  const programPoints = program === PayPrograms.Plus ? PlusPoints : PremiumPoints;

  const canSubmit = typeof offeringId !== 'undefined' && (hasRoadside ? passengerVehicle : true);

  return (
    <Form action="/billing" method="post" onSubmit={handleSubmit}>
      <Text as="p">
        Choose between monthly or annual billing. Monthly billing charges your bank account each month. Annual billing charges your bank once a year.
      </Text>
      <Text as="p">
        Important Membership Details:
      </Text>
      <ul>
        {programPoints.map(point => (
          <li key={point.key}>{point.description}</li>
        ))}
      </ul>
      <Text as="p" italic>
        You won’t be charged until you review and submit on the next step
      </Text>
      <br />
      <br />
      {options.map(offer => (
        <MembershipOption
          id={offer.plan_offering_id}
          key={offer.plan_offering_id}
          isSelected={offeringId === offer.plan_offering_id}
          title={`${program} ${offer.period_type === 'month' ? 'Monthly' : 'Annual'} Billing`}
          onSelect={handleOfferChange}
          periodType={offer.period_type}
          price={offer.renewal_price}
        />
      ))}
      <MembershipOption
        id={0}
        isSelected={offeringId === 0}
        title="No thanks. I would like to continue with the free plan."
        onSelect={handleOfferChange}
      />
      {hasRoadside && (
        <React.Fragment>
          <br />
          <PassengerVehicleCheckbox onChange={handlePassengerVehicleChange} />
        </React.Fragment>
      )}
      <br />
      <br />
      <FormActionButtons
        canSubmit={canSubmit}
        primaryButtonText="Review Purchase"
      />
    </Form>
  );
}

MembershipBilling.propTypes = {
  offeringId: PropTypes.number,
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    plan_offering_id: PropTypes.number,
    has_roadside: PropTypes.bool,
    period_type: PropTypes.oneOf(['month', 'year']),
    renewal_price: PropTypes.string,
  })),
  program: ProgramPropType,
};

MembershipBilling.defaultProps = {
  offeringId: undefined,
  onSubmit: () => {},
  options: [],
  program: PayPrograms.Premium,
};
