import React, { useCallback, useState } from 'react';
import { Form, Link, Text } from '@gasbuddy/react-components';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import EsignTermsCheckbox from '../EsignTermsCheckbox';
import FormActionButtons from '../FormActionButtons';
import EnrollmentStepsOverview from './EnrollmentStepsOverview';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function GetStarted({
  consumerHost,
  error,
  esigned,
  isSavingConsent,
  recordEsignConsent,
}) {
  const [acceptedEsignTerms, setAcceptedEsignTerms] = useState(false);

  useTracking(ANALYTICS_SCREENS.PAYPLUS_ENROLL_START);

  const handleTermsToggled = useCallback((e) => {
    setAcceptedEsignTerms(e.target.checked);
  }, []);

  const handleSubmit = useCallback((e) => {
    recordEsignConsent(e);
  }, [recordEsignConsent]);

  if (esigned) {
    return (
      <Redirect to="/enroll" />
    );
  }

  return (
    <Form method="post" action="/esign" onSubmit={handleSubmit}>
      <EnrollmentStepsOverview />
      <br />
      <EsignTermsCheckbox onTermsChecked={handleTermsToggled} />
      {!!error && (
        <Text as="p" color="orange">
          There was an error saving your consent. Please try again.
        </Text>
      )}
      <FormActionButtons
        primaryButtonText="Continue"
        canSubmit={acceptedEsignTerms}
        loading={isSavingConsent}
      />
      <br />
      <Text bold color="midnight">
        Still have questions?
        Check out our <Link bold href={`//${consumerHost}/pay#faq`} target="_blank" rel="noopener noreferrer">FAQs</Link>.
      </Text>
    </Form>
  );
}

GetStarted.propTypes = {
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  esigned: PropTypes.bool,
  isSavingConsent: PropTypes.bool,
  recordEsignConsent: PropTypes.func,
};

GetStarted.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  esigned: false,
  isSavingConsent: false,
  recordEsignConsent: () => {},
};
