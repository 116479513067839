import { connect } from 'react-redux';
import SwitchToNewProgramFlow from './SwitchToNewProgramFlow';
import { requestNewProgramCard } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ payCard, config }) {
  const { canSwitch, error, wasSwitched, isSwitching } = payCard;

  return {
    canSwitch,
    consumerHost: config.consumerHost,
    error: getErrorMessage(error, 'Your Pay with GasBuddy card is still active and we cannot request a new card at this time.'),
    isSwitching,
    wasSwitched,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestNewProgramCard: () => {
      dispatch(requestNewProgramCard());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToNewProgramFlow);
