import { connect } from 'react-redux';
import PaymentError from './PaymentError';

function mapStateToProps({ wallet }) {
  return {
    paymentInProgress: !!(
      wallet?.account_status === 'balance_settlement_pending'
      && wallet?.banners?.length
      && wallet?.disposition?.recent_payment
    ),
  };
}

export default connect(mapStateToProps)(PaymentError);
