import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Header, Loader, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import Container from '../../components/Container';
import RenewCardForm from '../../components/RenewCardForm';
import NewCardSuccess from '../../components/NewCardSuccess';
import Panel from '../../components/Panel';

function RenewCardFlow({
  autoConfirm,
  canRenew,
  error,
  renewCard,
  wasRenewed,
}) {
  const shouldAutoRenew = !!(canRenew && !wasRenewed && autoConfirm && renewCard);

  useEffect(() => {
    if (shouldAutoRenew) {
      renewCard();
    }
  }, [renewCard, shouldAutoRenew]);

  if (error) {
    return (
      <Container>
        <Panel>
          <Header as="h2">Failed to Renew Card</Header>
          <Text as="p">{error}</Text>
        </Panel>
      </Container>
    );
  }

  if (!canRenew) {
    return (
      <Redirect to="/enroll" />
    );
  }

  if (wasRenewed) {
    return (
      <Container>
        <NewCardSuccess
          renewed
          subheader="You can still use your current card until the expiration date."
        />
      </Container>
    );
  }

  if (shouldAutoRenew) {
    return (
      <Container>
        <Panel>
          <Loader>Renewing Card...</Loader>
        </Panel>
      </Container>
    );
  }

  return (
    <Container>
      <Panel>
        <Header as="h2">Shipping Address</Header>
        <RenewCardForm />
      </Panel>
    </Container>
  );
}

RenewCardFlow.propTypes = {
  autoConfirm: PropTypes.bool,
  canRenew: PropTypes.bool,
  error: PropTypes.string,
  renewCard: PropTypes.func,
  wasRenewed: PropTypes.bool,
};

RenewCardFlow.defaultProps = {
  autoConfirm: false,
  canRenew: false,
  error: undefined,
  renewCard: undefined,
  wasRenewed: false,
};

export default RenewCardFlow;
