import AccountStatus from '../../constants/accountStatus';
import MigratingCardActivationComplete from '../../components/MigratingCardActivationComplete';
import MigratingCardActivation from '../../components/MigratingCardActivation';
import MigratingCardPaymentOptions from '../../components/MigratingCardPaymentOptions';

export default function getMigrationConfig(wallet = {}, options = {}) {
  const { hasPaymentOptionSet } = options;
  const {
    account_status: accountStatus,
    ship_date: shipDate,
  } = wallet;

  const newCardShipped = !!shipDate;
  const cardUnderMigration = accountStatus === AccountStatus.MigratedUserCardActivation;
  const eligibleForMigration = cardUnderMigration && newCardShipped;

  return [
    {
      id: 'inprogress',
      label: 'In Progress',
      progressBar: 'activation',
    },
    {
      id: 'migration',
      label: 'On the Way',
      progressBar: 'activation',
    },
    {
      id: 'payment-options',
      label: 'Payment Options',
      title: 'ACH Payment Options',
      requiresAttention: eligibleForMigration && !hasPaymentOptionSet,
      component: MigratingCardPaymentOptions,
    },
    {
      id: 'activate',
      label: 'Activation',
      progressBar: 'activation',
      requiresAttention: hasPaymentOptionSet && accountStatus !== AccountStatus.ReadyToTransact,
      component: MigratingCardActivation,
    },
    {
      id: 'activated',
      label: 'Activated',
      progressBar: 'activation',
      requiresAttention: accountStatus === AccountStatus.ReadyToTransact,
      component: MigratingCardActivationComplete,
    },
  ];
}
