export const ANALYTICS_SCREENS = {
  // Enrollment screens
  PAYPLUS_ENROLL_IDENTITY: 'PayPlus_Enroll_Identity',
  PAYPLUS_ENROLL_START: 'PayPlus_Enroll_Start',
  PAYPLUS_ENROLL_DETAILS: 'PayPlus_Enroll_Details',
  PAYPLUS_ENROLL_LOGIN: 'PayPlus_Enroll_Login',
  PAYPLUS_ENROLL_SHIPPING: 'PayPlus_Enroll_Shipping',
  PAYPLUS_ENROLL_BANK_START: 'PayPlus_Enroll_Bank_Start',
  PAYPLUS_ENROLL_ACH_PAYMENT: 'PayPlus_Enroll_ACH_Payment',
  PAYPLUS_ENROLL_BANK_SEARCH: 'PayPlus_Enroll_Bank_Search',
  PAYPLUS_ENROLL_BANK_MICRODEPOSIT: 'PayPlus_Enroll_Bank_MicroDeposit',
  PAYPLUS_ENROLL_CONFIRM: 'PayPlus_Enroll_Confirm',
  PAYPLUS_ENROLL_SUCCESS: 'PayPlus_Enroll_Success',
  // Credit card enrollment
  PAYPLUS_ENROLL_CREDIT_COMPLETE: 'PayPlus_Enroll_Credit_Complete',
  // Card Activation
  PAYPLUS_ACTIVATE_CONFIRM_DIGITS: 'PayPlus_Activate_Confirm_Digits',
  PAYPLUS_ACTIVATE_SUCCESS: 'PayPlus_Activate_Success',
  // Premium upgrade
  PAYPLUS_PREMIUM_PURCHASE_CREDIT_CARD: 'PayPlus_Premium_Purchase_CC',
  PAYPLUS_PREMIUM_BILLING_OPTIONS: 'PayPlus_Enroll_Plan_Selection',
  PAYPLUS_PREMIUM_UPGRADE: 'PayPlus_Membership_Purchase',
  PAYPLUS_PREMIUM_UPGRADED: 'PayPlus_Membership_Complete',
  // Pay card migration
  PAYPLUS_MIGRATE_CHANGE_ADDRESS: 'PayPlus_Migrate_Change_Address',
  PAYPLUS_MIGRATE_REVIEW_ADDRESS: 'PayPlus_Migrate_Review_Address',
  PAYPLUS_MIGRATE_SUCCESS: 'PayPlus_Migrate_Success',
  PAYPLUS_MIGRATE_ACTIVATION_ACHPAYMENT: 'PayPlus_Migrate_Activation_ACHPayment',
  PAYPLUS_MIGRATE_ACTIVATION_CONFIRM_DIGITS: 'PayPlus_Migrate_Activation_Confirm_Digits',
  PAYPLUS_MIGRATE_ACTIVATION_SUCCESS: 'PayPlus_Migrate_Activation_Success',
  // Payment option update
  PAYPLUS_UPDATE_ACH_PAYMENT: 'PayPlus_Update_ACH_Payment',
  // Pay card expiring
  PAYPLUS_EXPIRED_CARD_ADDRESS_CONFIRMED: 'PayPlus_Card_Expiration_Confirm_Address_Complete_Opened',
  // Redeem GasBack
  PAYPLUS_REDEEM_TYPES: 'PayPlus_Redeem_Types',
  PAYPLUS_REDEEM_CHARITY_SELECT: 'PayPlus_Redeem_Charity_Select',
  PAYPLUS_REDEEM_CHARITY_REVIEW: 'PayPlus_Redeem_Charity_Review',
  PAYPLUS_REDEEM_CHARITY_COMPLETE: 'PayPlus_Redeem_Charity_Complete',
  PAYPLUS_REDEEM_GIFTCARD_SELECT: 'PayPlus_Redeem_GiftCard_Select',
  PAYPLUS_REDEEM_GIFTCARD_DETAILS: 'PayPlus_Redeem_GiftCard_Details',
  PAYPLUS_REDEEM_GIFTCARD_REVIEW: 'PayPlus_Redeem_GiftCard_Review',
  PAYPLUS_REDEEM_GIFTCARD_PENDING: 'PayPlus_Redeem_GiftCard_Pending',
  PAYPLUS_REDEEM_GIFTCARD_PURCHASE: 'PayPlus_Redeem_GiftCard_Purchase',
  PAYPLUS_REDEEM_GIFTCARD_COMPLETE: 'PayPlus_Redeem_GiftCard_Complete',
  // Redeem Reward Code
  PAYPLUS_REWARD_CODE_REDEEM: 'PayPlus_Reward_Code_Redemption',
  // Settle periodic statement balance
  PAYPLUS_PERIODIC_PAYMENT_ATTEMPTED: 'PayPlus_Periodic_Payment_Attempted',
  PAYPLUS_PERIODIC_PAYMENT_ATTEMPT_FAILED: 'PayPlus_Periodic_Payment_Attempt_Failed',
};

export const ANALYTICS_EVENTS = {
  // Enrollment screen events
  PAYPLUS_ENROLL_BANK_START_IAV: 'PayPlus_Enroll_Bank_Start_IAV',
  PAYPLUS_ENROLL_BANK_SEARCH_COMPLETE: 'PayPlus_Enroll_Bank_Search_Complete',
  PAYPLUS_ENROLL_BANK_SEARCH_EXIT: 'PayPlus_Enroll_Bank_Search_Exit',
  PAYPLUS_ENROLL_BANK_SEARCH_ERROR: 'PayPlus_Enroll_Bank_Search_Error',
  PAYPLUS_ENROLL_BANK_SEARCH_NO_ACCOUNTS_FOUND: 'PayPlus_Enroll_Bank_Search_No_Accounts_Found',
  PAYPLUS_ENROLL_BANK_LOGIN: 'PayPlus_Enroll_Bank_Login',
  PAYPLUS_ENROLL_BANK_NOT_FOUND: 'PayPlus_Enroll_Bank_Not_Found',
  PAYPLUS_ENROLL_BANK_START_MANUAL: 'PayPlus_Enroll_Bank_Start_Manual',
  PAYPLUS_ENROLL_ACH_PAYMENT_SELECTED: 'PayPlus_Enroll_ACH_Payment_Selected',
  PAYPLUS_ENROLL_CONFIRM_SUBMIT_TAPPED: 'PayPlus_Enroll_Confirm_Submit_Tapped',
  PAYPLUS_ENROLL_EDIT_TAPPED: 'PayPlus_Enroll_Edit_Tapped',
  // Pay card migration events
  PAYPLUS_MIGRATE_ACTIVATION_ACHPAYMENT_SELECTED: 'PayPlus_Migrate_Activation_ACHPayment_Selected',
  // Redeem GasBack events
  PAYPLUS_REDEEM_BALANCE_INFO_CLICKED: 'PayPlus_Redeem_Balance_Info_Clicked',
  PAYPLUS_REDEEM_TYPES_SUBMIT_CLICKED: 'PayPlus_Redeem_Types_Submit_Clicked',
  PAYPLUS_REDEEM_TYPES_CANCEL_CLICKED: 'PayPlus_Redeem_Types_Cancel_Clicked',
  PAYPLUS_REDEEM_CHARITY_SELECT_SUBMIT_CLICKED: 'PayPlus_Redeem_Charity_Select_Submit_Clicked',
  PAYPLUS_REDEEM_CHARITY_SELECT_CANCEL_CLICKED: 'PayPlus_Redeem_Charity_Select_Cancel_Clicked',
  PAYPLUS_REDEEM_CHARITY_REVIEW_SUBMIT_CLICKED: 'PayPlus_Redeem_Charity_Review_Submit_Clicked',
  PAYPLUS_REDEEM_CHARITY_REVIEW_CANCEL_CLICKED: 'PayPlus_Redeem_Charity_Review_Cancel_Clicked',
  PAYPLUS_REDEEM_CHARITY_COMPLETE_CONTINUE_CLICKED: 'PayPlus_Redeem_Charity_Complete_Continue_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_SELECT_SUBMIT_CLICKED: 'PayPlus_Redeem_GiftCard_Select_Submit_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_SELECT_CANCEL_CLICKED: 'PayPlus_Redeem_GiftCard_Select_Cancel_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_DETAILS_SUBMIT_CLICKED: 'PayPlus_Redeem_GiftCard_Details_Submit_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_DETAILS_CANCEL_CLICKED: 'PayPlus_Redeem_GiftCard_Details_Cancel_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_REVIEW_WRONG_EMAIL: 'PayPlus_Redeem_GiftCard_Review_Wrong_Email_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_REVIEW_SUBMIT_CLICKED: 'PayPlus_Redeem_GiftCard_Review_Submit_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_REVIEW_CANCEL_CLICKED: 'PayPlus_Redeem_GiftCard_Review_Cancel_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_PENDING_CONTINUE_CLICKED: 'PayPlus_Redeem_GiftCard_Pending_Continue_Clicked',
  PAYPLUS_REDEEM_GIFTCARD_PURCHASE_SUBMIT_CLICKED: 'PayPlus_Redeem_GiftCard_Purchase_Submit_Clicked',
  PAYPLUS_REWARD_CODE_REDEEM_CODE_SUBMITTED: 'PayPlus_Reward_Code_Redemption_Code_Submitted',
  PAYPLUS_REWARD_CODE_REDEEM_SUBMISSION_FAILED: 'PayPlus_Reward_Code_Redemption_Submission_Failed',
  PAYPLUS_REWARD_CODE_REDEEM_SUBMISSION_SUCCEEDED: 'PayPlus_Reward_Code_Redemption_Submission_Succeeded',
  PAYPLUS_REWARD_CODE_REDEEM_SUBMIT_ANOTHER_CODE_CLICKED: 'PayPlus_Reward_Code_Redemption_Submit_Another_Code_Clicked',
  // IAM events
  LOGGED_IN: 'IAM_User_Successfully_Logged_In', // Same as identity-web
  REGISTERED: 'IAM_User_Successfully_Registered', // Same as identity-web
  // Payment options update events
  PAYPLUS_UPDATE_ACH_PAYMENT_SELECTED: 'PayPlus_Update_ACH_Payment_Selected',
};

export const LOCALYTICS_CUSTOM_DIMENSIONS = {
  USER_ID: 14,
  USER_NAME: 15,
};
