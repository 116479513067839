import { Constraint, Header, Image, Loader, Modal, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import { CharityIdPropType, GiftCardVendorPropType } from '../../prop-types/gasback';
import FlowContainer from '../FlowContainer';
import styles from './GasBackTransferStep.module.css';

const cx = classnames.bind(styles);

function GasBackBalanceExplanation() {
  return (
    <React.Fragment>
      <Header as="h3">Your available GasBack transfer balance</Header>
      <Text as="p">
        This is the available GasBack balance that you are eligible to transfer to a digital gift card or donate to a charity.
        Pending GasBack funds and issued GasBack for promotions are not available for transfer.
      </Text>
    </React.Fragment>
  );
}

const SCREENS = [
  '/gasback/transfer/bank/confirm',
  '/gasback/transfer/bank/verify',
  '/gasback/transfer/giftcard/details',
  '/gasback/transfer/giftcard/confirm',
  '/gasback/transfer/charity/confirm',
];

function GasBackTransferStep({
  backLinkHref,
  backLinkText,
  balance,
  children,
  className,
  consumerHost,
  context,
  fluid,
  idempotencyId,
  location,
  smallHeader,
  subtitle,
  title,
  transferDetails,
  ...rest
}) {
  const [isShowingModal, setIsShowingModal] = useState(false);
  const { trackEvent } = useTracking();

  const handleQuestionClick = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_BALANCE_INFO_CLICKED, context);
    setIsShowingModal(true);
  }, [context, trackEvent]);

  const handleCancelQuestion = useCallback(() => {
    setIsShowingModal(false);
  }, []);

  // If idempotency id is missing, redirect user to my savings page
  if (!idempotencyId) {
    window.location.href = `//${consumerHost}/account/savings`;
    return null;
  }

  const { pathname: currentUrlPath } = location;
  if (SCREENS.includes(currentUrlPath)) {
    const [
      bankConfirmationScreen,
      microDepositVerificationScreen,
      giftCardDetailsScreen,
      giftCardConfirmationScreen,
      donationConfirmationScreen,
    ] = SCREENS;

    // redux state would only have partial details available among below fields depending on what flow user is in
    const {
      routingNumber,
      accountNumber,
      vendorId,
      charityId,
      instrumentId,
      amount,
      donationAmount,
      recipientEmail,
      firstName,
      lastName,
    } = transferDetails;

    // TODO: Handle redirects made here through a higher order component
    let isMissingRequiredInfo = false;

    if (currentUrlPath === bankConfirmationScreen) {
      isMissingRequiredInfo = !amount || !recipientEmail || !routingNumber || !accountNumber || accountNumber.length < 4;
    } else if (currentUrlPath === microDepositVerificationScreen) {
      isMissingRequiredInfo = !instrumentId || !accountNumber || !amount;
    } else if (currentUrlPath === giftCardDetailsScreen) {
      isMissingRequiredInfo = !vendorId || !amount || !recipientEmail;
    } else if (currentUrlPath === giftCardConfirmationScreen) {
      isMissingRequiredInfo = !vendorId || !amount || !recipientEmail || !firstName || !lastName;
    } else if (currentUrlPath === donationConfirmationScreen) {
      isMissingRequiredInfo = !charityId || !donationAmount || !recipientEmail;
    }

    if (isMissingRequiredInfo) {
      return (
        <Redirect to="/gasback/transfer" />
      );
    }
  }

  if (typeof balance === 'undefined') {
    return (
      <Loader size="lg" />
    );
  }

  return (
    <React.Fragment>
      <Modal
        size="sm"
        content={() => (
          <GasBackBalanceExplanation />
        )}
        onClose={handleCancelQuestion}
        forceIsShowing={isShowingModal}
      />
      <FlowContainer
        backLinkHref={backLinkHref}
        backLinkText={backLinkText}
        title={title}
        subtitle={subtitle
          ? (
            <Text className={cx('subtitle')}>{subtitle}</Text>
          ) : (
            <React.Fragment>
              <Text size="lg" className={cx('subtitle')}>
                {formatPrice(balance)} available for withdrawal
              </Text>
              &nbsp;
              <Image
                src="//static.gasbuddy.com/web/icons/question-circle.svg"
                alt="Question"
                onClick={handleQuestionClick}
                className={cx('questionIcon')}
              />
            </React.Fragment>
          )}
        className={cx({ smallHeader }, className)}
        {...rest}
      >
        <Constraint tablet={fluid ? 12 : 9}>
          {children}
        </Constraint>
      </FlowContainer>
    </React.Fragment>
  );
}

GasBackTransferStep.propTypes = {
  backLinkHref: PropTypes.string,
  backLinkText: PropTypes.string,
  balance: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  consumerHost: PropTypes.string,
  context: PropTypes.shape(),
  fluid: PropTypes.bool,
  idempotencyId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  smallHeader: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  transferDetails: PropTypes.shape({
    routingNumber: PropTypes.string,
    accountNumber: PropTypes.string,
    vendorId: GiftCardVendorPropType,
    charityId: CharityIdPropType,
    instrumentId: PropTypes.string,
    amount: PropTypes.string,
    donationAmount: PropTypes.string,
    recipientEmail: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

GasBackTransferStep.defaultProps = {
  backLinkHref: undefined,
  backLinkText: undefined,
  balance: undefined,
  children: undefined,
  className: undefined,
  consumerHost: 'www.gasbuddy.com',
  context: undefined,
  fluid: false,
  idempotencyId: undefined,
  location: {
    pathname: undefined,
  },
  smallHeader: false,
  subtitle: undefined,
  title: 'Transfer GasBack Funds',
  transferDetails: {},
};

GasBackTransferStep.Actions = FlowContainer.Actions;

GasBackTransferStep.Button = FlowContainer.Button;

export default GasBackTransferStep;
