// TODO: This change should be moved to @gasbuddy/react-hooks package
// This was added temporarily to start getting localytics events working
import { EventEmitter } from 'events';
import { useMemo, useEffect, useState } from 'react';

const getDocument = () => document;

const getWindow = () => window;

/* eslint-disable */
function initLocalyticsScript(key, options) {
  (function(l, y, t, i, c, s) {
    l['LocalyticsGlobal'] = i;
    l[i] = function() {
      (l[i].q = l[i].q || []).push(arguments)
    };
    l[i].t = +new Date;
    (s = y.createElement(t)).type = 'text/javascript';
    s.src = '//web.localytics.com/v4/localytics.min.js';
    (c = y.getElementsByTagName(t)[0]).parentNode.insertBefore(s, c);
    ll('init', key, options);
  })(
    getWindow(),
    getDocument(),
    'script',
    'll',
  );
}
/* eslint-enable */

/**
 * Tests if a given object has a value other than null or undefined
 * @param {any} obj - Any JavaScript object
 */
const hasValue = obj => typeof obj !== 'undefined' && obj !== null;

/**
 * Takes an object and returns an object with only properties that have values other than null
 * or undefined
 * @param {object} obj - A JavaScript object
 */
const compact = obj => Object.keys(obj)
  .filter(k => hasValue(obj[k]))
  .reduce((newObj, k) => ({ ...newObj, [k]: obj[k] }), {});

/**
 * Memoize Localytics configuration
 * See https://help.uplandsoftware.com/localytics/dev/web.html#init-options-web for info
 *
 * @param {Object} config An object with Localytics configuration options
 * @returns Object
 */
function useLocalyticsOptions(config = {}) {
  const {
    appVersion,
    sessionTimeout,
    customDimensions,
    customerId,
    customerEmail,
    customerName,
    domain,
    key,
    trackPageView,
    cacheOffline,
    cookies,
    useXHR,
    autoUpload,
    workerPath,
    workerName,
    publicKey,
    allowLogs,
    disableAnalyticsTracking,
    optedOut,
    privacyOptedOut,
  } = config;

  return useMemo(() => compact({
    appVersion,
    sessionTimeout,
    customDimensions,
    customerId,
    customerEmail,
    customerName,
    domain,
    key,
    trackPageView,
    cacheOffline,
    cookies,
    useXHR,
    autoUpload,
    workerPath,
    workerName,
    publicKey,
    allowLogs,
    disableAnalyticsTracking,
    optedOut,
    privacyOptedOut,
  }), [
    appVersion,
    sessionTimeout,
    customDimensions,
    customerId,
    customerEmail,
    customerName,
    domain,
    key,
    trackPageView,
    cacheOffline,
    cookies,
    useXHR,
    autoUpload,
    workerPath,
    workerName,
    publicKey,
    allowLogs,
    disableAnalyticsTracking,
    optedOut,
    privacyOptedOut,
  ]);
}

export default function useLocalytics(config) {
  const options = useLocalyticsOptions(config?.localytics);
  const isBrowser = typeof window !== 'undefined';
  const [initComplete, setInitComplete] = useState(isBrowser && !!window.ll); // window is undefined in Node

  const analytics = useMemo(() => {
    const ReactAnalytics = {
      isInitialized: {
        localytics: initComplete,
      },

      identifyUser(userConfig) {
        const { ignore = [], ...rest } = userConfig;
        if (isBrowser && initComplete && !ignore.includes('localytics')) {
          window.ll('setCustomerId', rest.id);
        }
      },

      // no need for ignoredSystems here as only Localytics uses this so far
      setCustomDimension(index, value) {
        if (isBrowser && initComplete) {
          window.ll('setCustomDimension', index, value);
        }
      },

      tagPageView(pageViewConfig) {
        const { name: pageName, attributes, eventPostfix = '_Opened', ignore = [] } = pageViewConfig;
        if (initComplete) {
          if (isBrowser && initComplete && !ignore.includes('localytics')) {
            window.ll('tagScreen', pageName);
          }

          this.tagEvent({ name: `${pageName}${eventPostfix}`, attributes, ignore });
        }
      },

      tagEvent(eventConfig) {
        const { name: eventName, attributes, ignore = [] } = eventConfig;
        if (initComplete) {
          this.emit('event', eventName, attributes);

          if (isBrowser && initComplete && !ignore.includes('localytics')) {
            window.ll('tagEvent', eventName, attributes);
          }
        }
      },
    };

    Object.assign(ReactAnalytics, EventEmitter.prototype);

    return ReactAnalytics;
  }, [isBrowser, initComplete]);

  useEffect(() => {
    if (!initComplete && options.key) {
      const { key, ...rest } = options;
      initLocalyticsScript(key, rest);
      setInitComplete(true);
    }

    return () => {
      analytics.removeAllListeners('event');
    };
  }, [analytics, initComplete, options]);

  return analytics;
}
