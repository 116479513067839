import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { Button, Form, Header, Input, Text, Message } from '@gasbuddy/react-components';
import getQueryParamsFromLocation from '../../../lib/utils/getQueryParamsFromLocation';

function PromoEntry({ location }) {
  const queryParams = getQueryParamsFromLocation(location);

  return (
    <Form aria-label="Promotion Entry Form" method="POST">
      <Header>Get Your GasBack</Header>
      {queryParams.error && (
        <Message color="red" header="Oops" icon={<FontAwesomeIcon icon={faExclamation} />}>
          {queryParams.error === 'redeemed' ? 'The code you entered has already been redeemed.' : 'The code you entered is not valid.'}
        </Message>
      )}
      <Text as="p">
        GasBack means free gas! GasBack is redeemed by using the Pay with GasBuddy+™ card.
        It&apos;s free to sign up, and our card saves you up to 25¢ per gallon at stations nationwide.
      </Text>
      <Text as="p">
        To enroll, securely link your checking account and tell us where to send your card.
        No credit check is necessary. To get started, enter the GasBack code you received.
      </Text>
      <Input label="Enter Your Code" name="code" />
      <Button primary type="submit">Submit</Button>
    </Form>
  );
}

PromoEntry.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(PromoEntry);
