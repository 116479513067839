import { connect } from 'react-redux';
import MigratingCardActivation from './MigratingCardActivation';
import { migrateCard } from '../../reducers/actions';
import { getPayCard } from '../../../lib/utils/cardInstruments';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ wallet, instruments }) {
  const { cardActivateError, isActivatingCard } = instruments;

  return {
    card: getPayCard(wallet.status?.instruments),
    error: getErrorMessage(cardActivateError),
    isSaving: isActivatingCard,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(migrateCard(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MigratingCardActivation);
