import { connect } from 'react-redux';
import PaymentOptionUpdateSuccess from './PaymentOptionUpdateSuccess';
import { fetchCurrentPaymentOption } from '../../reducers/actions';

function mapStateToProps({ progress, config, wallet }) {
  return {
    consumerHost: config.consumerHost,
    paymentOption: progress.paymentOption,
    payProgram: wallet.membership?.type,
    isLoading: progress.isLoading,
    error: progress.error && 'An error occurred fetching the details of your updated payment option. Your payment option was still updated, though.',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPaymentOption: () => dispatch(fetchCurrentPaymentOption()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionUpdateSuccess);
