import { Image } from '@gasbuddy/react-components';
import React from 'react';
import PropTypes from 'prop-types';

export default function PwGBCardImage({ tilted, ...rest }) {
  return (
    <Image
      alt="Pay with GasBuddy+™ Card"
      src={
        tilted
          ? 'https://static.gasbuddy.com/web/pay/svg/pwgb-plus-card-horizontal-shadow.svg'
          : 'https://static.gasbuddy.com/web/pay/svg/pwgb-plus-card--horizontal.svg'
      }
      width={tilted ? 220 : 80}
      {...rest}
    />
  );
}

PwGBCardImage.propTypes = {
  tilted: PropTypes.bool,
};

PwGBCardImage.defaultProps = {
  tilted: false,
};
