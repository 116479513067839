import { connect } from 'react-redux';
import UpdatePaymentOptionsFlow from './UpdatePaymentOptionsFlow';
import { fetchCurrentPaymentOption } from '../../reducers/actions';

function mapStateToProps({ progress, wallet }) {
  return {
    paymentOption: progress.paymentOption,
    canSwitchPaymentOption: progress.canSwitchPaymentOption,
    isLoading: progress.isLoading,
    error: progress.error,
    payProgram: wallet.membership?.type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPaymentOption: () => dispatch(fetchCurrentPaymentOption()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentOptionsFlow);
