import { connect } from 'react-redux';
import SwitchToAutoPay from './SwitchToAutoPay';
import { fetchCurrentPaymentOption, switchToAutoPayOption } from '../../reducers/actions';

function mapStateToProps({ progress, config }) {
  return {
    paymentOption: progress.paymentOption,
    canSwitchPaymentOption: progress.canSwitchPaymentOption,
    consumerHost: config.consumerHost,
    amountOwed: progress.amountOwed,
    statementNotYetGenerated: progress.statementNotYetGenerated,
    isSwitchingPaymentOption: progress.isLoading,
    switchedPaymentOption: progress.switchedPaymentOption,
    error: progress.error && 'There was an error updating your payment option. Please try again later.',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPaymentOption: () => dispatch(fetchCurrentPaymentOption()),
    switchToAutoPayOption: (e, body) => {
      e.preventDefault();
      dispatch(switchToAutoPayOption(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToAutoPay);
