import { connect } from 'react-redux';
import MembershipBillingSummary from './MembershipBillingSummary';
import { setOfferingId } from '../../reducers/actions';
import formatPrice from '../../../lib/utils/formatPrice';
import PayPrograms from '../../constants/payPrograms';

function mapStateToProps({ membership, progress }) {
  const {
    plus: {
      annual: annualPlusOffer,
      monthly: monthlyPlusOffer,
    },
    premium: {
      annual: annualPremiumOffer,
      monthly: monthlyPremiumOffer,
    },
  } = membership;

  const plusOptions = [
    monthlyPlusOffer,
    annualPlusOffer,
  ];
  const premiumOptions = [
    monthlyPremiumOffer,
    annualPremiumOffer,
  ];

  const { offeringId } = progress;
  const selectedPlusOffer = plusOptions.find(offer => offer.plan_offering_id === offeringId);
  const selectedPremiumOffer = premiumOptions.find(offer => offer.plan_offering_id === offeringId);
  const selectedOffer = selectedPlusOffer || selectedPremiumOffer;
  const isMonthly = selectedOffer?.period_type === 'month';
  const program = selectedPlusOffer ? PayPrograms.Plus : PayPrograms.Premium;

  return {
    title: selectedOffer ? `${program} ${isMonthly ? 'Monthly' : 'Annual'} Billing` : 'Free Pay With GasBuddy+™',
    price: selectedOffer && `${formatPrice(selectedOffer?.renewal_price)}/${isMonthly ? 'mo' : 'yr'}`,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: ({ offeringId }) => dispatch(setOfferingId(offeringId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipBillingSummary);
