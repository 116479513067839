import { Constraint, Text, Actions } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import CardActivationHeader from '../CardActivationHeader';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function CardActivationComplete({ consumerHost }) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_ACTIVATE_SUCCESS);

  return (
    <Constraint desktop={10}>
      <CardActivationHeader
        title="All set!"
        subtitle="Your Pay with GasBuddy+™ card has been activated!"
      />
      <br />
      <Text style={{ fontSize: '1.1rem' }}>
        Use your Pay with GasBuddy+™ card on fuel & qualifying c-store purchases at more stations nationwide,
        everywhere Mastercard<sup>&reg;</sup> is accepted.
      </Text>
      <br />
      <br />
      <br />
      <Actions>
        <Actions.Button
          as="a"
          href={`//${consumerHost}/account/savings`}
          primary
          type="submit"
          tablet={6}
          desktop={5}
        >
          Go To Savings
        </Actions.Button>
        <Actions.Button
          as="a"
          href="https://routing.gasbuddy.com/app"
          target="_blank"
          secondary
          tablet={6}
          desktop={5}
        >
          Get The App
        </Actions.Button>
      </Actions>
    </Constraint>
  );
}

CardActivationComplete.propTypes = {
  consumerHost: PropTypes.string,
};

CardActivationComplete.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
