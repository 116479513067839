import { Form, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import CharityOptions from '../../constants/charityOptions';
import useTracking from '../../hooks/useTracking';
import { CharityIdPropType } from '../../prop-types/gasback';
import CardChoiceConfirmation from '../CardChoiceConfirmation';
import GasBackTransferStep from '../GasBackTransferStep';
import styles from './ConfirmGasBackCharityTransfer.module.css';

const cx = classnames.bind(styles);
const onlySingleCharityIsActive = CharityOptions.filter(o => o.enabled).length === 1;

export default function ConfirmGasBackCharityTransfer({
  charityId: chosenCharityId,
  consumerHost,
  donationAmount,
  error,
  history,
  idempotencyId,
  onSubmit,
  recipientEmail,
  transferInitiated,
  transferPending,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAYPLUS_REDEEM_CHARITY_REVIEW);
  const chosenCharity = useMemo(() => CharityOptions.find(o => o.id === chosenCharityId), [chosenCharityId]);

  const hasInitiatedTransfer = !transferPending && !error && transferInitiated;

  useEffect(() => {
    if (hasInitiatedTransfer) {
      history.push('/gasback/transfer/charity/success');
    }
  }, [hasInitiatedTransfer, history]);

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_CHARITY_REVIEW_SUBMIT_CLICKED);

    onSubmit(e, {
      idempotencyId,
      charityId: chosenCharityId,
      donationAmount,
    });
  }, [trackEvent, onSubmit, idempotencyId, chosenCharityId, donationAmount]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_CHARITY_REVIEW_CANCEL_CLICKED);
  }, [trackEvent]);

  if (!chosenCharity) {
    return (
      <Redirect to="/gasback/transfer/charity" />
    );
  }

  return (
    <GasBackTransferStep
      backLinkHref={onlySingleCharityIsActive ? '/gasback/transfer' : undefined}
      backLinkText={onlySingleCharityIsActive ? 'Choose Transfer Type' : 'Choose  Charity'}
      context={{ screen: ANALYTICS_SCREENS.PAYPLUS_REDEEM_CHARITY_REVIEW }}
    >
      <Form method="POST" action="/gasback/transfer/charity" loading={transferPending} onSubmit={handleSubmit}>
        <CardChoiceConfirmation
          title={`Confirm your donation to ${chosenCharity.name}`}
          description={(
            <React.Fragment>
              {chosenCharity.description}
              &nbsp;
              <Text as="a" bold className={cx('blueLink')} href={chosenCharity.url} target="_blank" rel="noreferrer">
                {chosenCharity.urlText}
              </Text>
            </React.Fragment>
          )}
          imageUrl={chosenCharity.imageUrl}
          cardVendorName={chosenCharity.name}
        >
          <input type="hidden" name="idempotencyId" value={idempotencyId} />
          <input type="hidden" name="charityId" value={chosenCharity.id} />
          <input type="hidden" name="donationAmount" value={donationAmount} />
          <Text as="p">Donation Amount</Text>
          <Text as="p" bold>{formatPrice(donationAmount)}</Text>
          <br />
          <Text as="p">Your confirmation email will be sent to</Text>
          <Text as="p" bold>{recipientEmail}</Text>
          <br />
          {/* TODO: Confirm and change details url */}
          {/* TODO: Confirm and change terms and conditions url */}
          <CardChoiceConfirmation.Terms
            title="Donation details"
            content={chosenCharity.donationDescription}
            withTermsLink
          />
          {!!error && (
            <Text as="p" color="orange">{error}</Text>
          )}
          <GasBackTransferStep.Actions>
            <GasBackTransferStep.Button type="submit" primary fluid>
              Confirm Donation
            </GasBackTransferStep.Button>
            <GasBackTransferStep.Button
              as="a"
              link
              href={`//${consumerHost}/account/savings`}
              onClick={handleCancel}
            >
              Cancel
            </GasBackTransferStep.Button>
          </GasBackTransferStep.Actions>
        </CardChoiceConfirmation>
      </Form>
    </GasBackTransferStep>
  );
}

ConfirmGasBackCharityTransfer.propTypes = {
  charityId: CharityIdPropType.isRequired,
  consumerHost: PropTypes.string,
  donationAmount: PropTypes.string.isRequired,
  error: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  idempotencyId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  recipientEmail: PropTypes.string.isRequired,
  transferInitiated: PropTypes.bool,
  transferPending: PropTypes.bool,
};

ConfirmGasBackCharityTransfer.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  history: {
    push: () => {},
  },
  onSubmit: () => {},
  transferInitiated: false,
  transferPending: false,
};
