import { Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import GiftCardVendors from '../../constants/giftCardVendors';
import useTracking from '../../hooks/useTracking';
import { GiftCardVendorPropType } from '../../prop-types/gasback';
import CardChoiceConfirmation from '../CardChoiceConfirmation';
import GasBackTransferStep from '../GasBackTransferStep';

export default function ProcessingGasBackGiftCard({
  cardHolderFirstName,
  cardHolderLastName,
  consumerHost,
  recipientEmail,
  transferAmount,
  vendorId: chosenGiftCardVendorId,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAYPLUS_REDEEM_GIFTCARD_PENDING);

  const chosenVendor = useMemo(() => GiftCardVendors.find(vendor => vendor.id === chosenGiftCardVendorId), [chosenGiftCardVendorId]);

  const onDone = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_GIFTCARD_PENDING_CONTINUE_CLICKED);
  }, [trackEvent]);

  if (!chosenVendor) {
    return (
      <Redirect to="/gasback/transfer/giftcard" />
    );
  }

  const title = 'Hooray! Your digital gift card code purchase has been submitted!';
  const subtitle = 'For security reasons we will be sending you a confirmation email. Once the confirmation has been submitted, we will send you your digital gift card code.';

  return (
    <GasBackTransferStep
      context={{ screen: ANALYTICS_SCREENS.PAYPLUS_REDEEM_GIFTCARD_PENDING }}
      title={title}
      subtitle={subtitle}
      smallHeader
    >
      <CardChoiceConfirmation
        imageUrl={chosenVendor.imageUrl}
        cardVendorName={chosenVendor.displayName}
      >
        <Text as="p">Gift Card Amount</Text>
        <Text as="p" bold>{formatPrice(transferAmount, 2)}</Text>
        <br />
        <Text as="p">Your Name</Text>
        <Text as="p" bold>{cardHolderFirstName} {cardHolderLastName}</Text>
        <br />
        <Text as="p">Your confirmation and gift code will be sent to</Text>
        <Text as="p" bold>{recipientEmail}</Text>
        <br />
        <CardChoiceConfirmation.Terms
          title="Gift card details"
          content={chosenVendor.terms}
          detailsUrl={chosenVendor.detailsUrl}
        />
        <br />
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            primary
            fluid
            as="a"
            href={`//${consumerHost}/account/savings`}
            onClick={onDone}
          >
            Done
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </CardChoiceConfirmation>
    </GasBackTransferStep>
  );
}

ProcessingGasBackGiftCard.propTypes = {
  cardHolderFirstName: PropTypes.string.isRequired,
  cardHolderLastName: PropTypes.string.isRequired,
  consumerHost: PropTypes.string,
  recipientEmail: PropTypes.string.isRequired,
  transferAmount: PropTypes.string.isRequired,
  vendorId: GiftCardVendorPropType.isRequired,
};

ProcessingGasBackGiftCard.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
