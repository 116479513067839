import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PayProgramContext from '../../context/payProgram';
import PayPrograms from '../../constants/payPrograms';
import GradientContainer from '../../components/GradientContainer';
import WalletPropType from '../../prop-types/wallet';
import useMemoState from '../../../lib/utils/useMemoState';
import getMigrationConfig from './getMigrationConfig';
import useWatch from '../../hooks/useWatch';
import canonicalizeString from '../../../lib/utils/canonicalizeString';
import scrollToTop from '../../../lib/utils/scrollToTop';
import isMembershipProgram from '../../../lib/utils/isMembershipProgram';

export default function MigrateCardFlow({
  basePath,
  goToStep,
  location,
  match,
  payHost,
  stepId,
  wallet,
  hasPaymentOptionSet,
}) {
  const history = useHistory();
  const { step: routerStepId } = match.params;
  const payProgram = useContext(PayProgramContext);
  const memoWallet = useMemoState(wallet);

  const steps = useMemo(() => {
    const hasPremium = !!memoWallet.membership?.roadside_member_id;
    const hasPlus = !!memoWallet.membership && !hasPremium;

    return getMigrationConfig(memoWallet, {
      wantsPlus: payProgram === PayPrograms.Plus,
      wantsPremium: payProgram === PayPrograms.Premium,
      hasPlus,
      hasPremium,
      hasPaymentOptionSet,
    });
  }, [payProgram, memoWallet, hasPaymentOptionSet]);

  const latestStep = steps.find(step => step.requiresAttention);
  const activeStep = steps.find(step => step.id === stepId);
  const currentStep = activeStep || latestStep;
  const isValidRoute = useCallback((id) => {
    const index = steps.findIndex(step => step.id === id);
    const latestIndex = steps.indexOf(latestStep);
    return index > -1 && index <= latestIndex;
  }, [latestStep, steps]);
  const routerStepChanged = useWatch(routerStepId);
  const currentStepId = currentStep.id;
  const currentStepChanged = useWatch(currentStepId, isValidRoute(routerStepId)); // Prevent initial history update if router loads with state
  let status;

  const canonicalPath = canonicalizeString(location?.pathname);

  const updateEditStep = useCallback((id) => {
    goToStep(isValidRoute(id) && latestStep.id !== id ? id : undefined);
  }, [goToStep, isValidRoute, latestStep.id]);

  const updateHistory = useCallback((id) => {
    const path = `${basePath}/migrate/${id}`;

    if (!isValidRoute(routerStepId)) {
      // Ensure that this moment in history is associated with a screen
      history.replace(path);
    } else if (routerStepId && id !== routerStepId) {
      history.push(path);
    }
  }, [basePath, history, isValidRoute, routerStepId]);

  // If the history changes, make sure that it's reflected in Redux
  useEffect(() => {
    if (routerStepChanged) {
      updateEditStep(routerStepId);
    }
  }, [routerStepChanged, routerStepId, updateEditStep]);

  // If the current step changes, make sure that it's reflected in history
  useEffect(() => {
    if (currentStepChanged) {
      updateHistory(currentStepId);
    }
  }, [currentStepChanged, currentStepId, updateHistory]);

  // Scroll to top of window when the user advances
  useEffect(() => {
    scrollToTop();
  }, [currentStep.progressBar]);

  const Component = currentStep.component;

  const logo = isMembershipProgram(payProgram) ? (
    <Image
      alt="GasBuddy+™ Premium Logo"
      src="https://static.gasbuddy.com/web/pay/svg/gasbuddy-plus-premium-logo-horizontal.svg"
      style={{ maxHeight: 60 }}
    />
  ) : (
    <Image
      alt="Pay with GasBuddy+™ Logo"
      src="https://static.gasbuddy.com/web/pay/svg/pwgb-plus-logo.svg"
      style={{ maxHeight: 60 }}
    />
  );

  const mainContent = (
    <Component
      basePath={basePath}
      goToStep={updateEditStep}
      program={payProgram}
    />
  );

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href={`//${payHost}${canonicalPath}`} />
      </Helmet>
      <GradientContainer
        extra={currentStep.extra && React.createElement(currentStep.extra)}
        logo={logo}
        sidebar={status}
        title={currentStep.title}
      >
        {mainContent}
      </GradientContainer>
    </React.Fragment>
  );
}

MigrateCardFlow.propTypes = {
  basePath: PropTypes.string,
  goToStep: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape(),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }),
  payHost: PropTypes.string,
  stepId: PropTypes.string,
  wallet: WalletPropType,
  hasPaymentOptionSet: PropTypes.bool,
};

MigrateCardFlow.defaultProps = {
  basePath: '',
  goToStep: () => {},
  location: {
    pathname: '',
  },
  match: {
    params: {},
  },
  payHost: 'pay.gasbuddy.com',
  stepId: undefined,
  wallet: {},
  hasPaymentOptionSet: false,
};
