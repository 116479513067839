import { Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import canonicalizeString from '../../../lib/utils/canonicalizeString';
import dedupe from '../../../lib/utils/dedupe';
import getEnrollmentConfig from './getEnrollmentConfig';
import scrollToTop from '../../../lib/utils/scrollToTop';
import useMemoState from '../../../lib/utils/useMemoState';
import EnrollmentSteps from '../../constants/enrollmentSteps';
import PayPrograms from '../../constants/payPrograms';
import PayProgramContext from '../../context/payProgram';
import useWatch from '../../hooks/useWatch';
import WalletPropType from '../../prop-types/wallet';
import GradientContainer from '../../components/GradientContainer';
import StatusBar from '../../components/StatusBar';
import AccountStatus from '../../constants/accountStatus';

function getLastMilestone(steps, activeStep) {
  const activeStepIndex = steps.indexOf(activeStep);
  const milestones = steps.slice(0, activeStepIndex + 1).filter(step => step.label);
  return milestones.length - 1;
}

export default function Enrollment({
  basePath,
  declinedMembership,
  failedIdentityVerification,
  goToStep,
  location,
  login,
  match,
  offeringId,
  payHost,
  promo,
  stepId,
  upgraded,
  wallet,
  welcome,
  hasPeriodicPaymentsSet,
  hasSubmittedIdvCheckBefore,
}) {
  const history = useHistory();
  const { step: routerStepId } = match.params;
  const payProgram = useContext(PayProgramContext);
  const memoWallet = useMemoState(wallet);
  const steps = useMemo(() => {
    const hasPremium = !!memoWallet.membership?.roadside_member_id;
    const hasPlus = !!memoWallet.membership && !hasPremium;

    return getEnrollmentConfig(memoWallet, {
      failedIdentityVerification,
      wantsPlus: payProgram === PayPrograms.Plus,
      wantsPremium: payProgram === PayPrograms.Premium,
      hasPlus,
      hasPremium,
      completedBilling: typeof offeringId !== 'undefined',
      showLogin: login,
      showPromoEntry: promo,
      showUpgraded: upgraded,
      showWelcome: welcome,
      hasPeriodicPaymentsSet,
      hasSubmittedIdvCheckBefore,
    });
  }, [
    failedIdentityVerification,
    login,
    offeringId,
    payProgram,
    promo,
    upgraded,
    memoWallet,
    welcome,
    hasPeriodicPaymentsSet,
    hasSubmittedIdvCheckBefore,
  ]);
  // This is the most advanced step that the user can access
  const latestStep = steps.find(step => step.requiresAttention);
  // This is a programmatic override in case a user requests a previously completed step
  const activeStep = steps.find(step => step.id === stepId);
  // This is the step that we show the user
  const currentStep = activeStep || latestStep;
  const isValidRoute = useCallback((id) => {
    const index = steps.findIndex(step => step.id === id);
    const latestIndex = steps.indexOf(latestStep);
    return index > -1 && index <= latestIndex;
  }, [latestStep, steps]);
  const routerStepChanged = useWatch(routerStepId);
  const currentStepId = currentStep.id;
  const currentStepChanged = useWatch(currentStepId, isValidRoute(routerStepId)); // Prevent initial history update if router loads with state
  const isEnrolling = !login && !promo && !welcome;
  const isMigrating = memoWallet.account_status === AccountStatus.MigratedUserCardActivation;
  let status;

  const canonicalPath = canonicalizeString(location?.pathname);

  const updateEditStep = useCallback((id) => {
    goToStep(isValidRoute(id) && latestStep.id !== id ? id : undefined);
  }, [goToStep, isValidRoute, latestStep.id]);

  const updateHistory = useCallback((id) => {
    const path = `${basePath}/enroll/${id}`;

    if (isEnrolling && !isValidRoute(routerStepId)) {
      // Ensure that this moment in history is associated with a screen
      history.replace(path);
    } else if (routerStepId && id !== routerStepId) {
      history.push(path);
    }
  }, [basePath, history, isEnrolling, isValidRoute, routerStepId]);

  // If the history changes, make sure that it's reflected in Redux
  useEffect(() => {
    if (routerStepChanged) {
      updateEditStep(routerStepId);
    }
  }, [routerStepChanged, routerStepId, updateEditStep]);

  // If the current step changes, make sure that it's reflected in history
  useEffect(() => {
    if (currentStepChanged) {
      updateHistory(currentStepId);
    }
  }, [currentStepChanged, currentStepId, updateHistory]);

  const handleBackClick = useCallback((e) => {
    e.preventDefault();
    const previousIndex = steps.indexOf(currentStep) - 1;
    updateEditStep(steps[previousIndex].id);
  }, [currentStep, steps, updateEditStep]);

  const handleSkipClicked = useCallback((e) => {
    e.preventDefault();
    const nextIndex = steps.indexOf(currentStep) + 1;
    updateEditStep(steps[nextIndex].id);
  }, [currentStep, steps, updateEditStep]);

  // Scroll to top of window when the user advances
  useEffect(() => {
    scrollToTop();
  }, [currentStep.progressBar]);

  // If the user is on a premium screen and declined membership, bring them to free screen
  if ([PayPrograms.Plus, PayPrograms.Premium].includes(payProgram) && declinedMembership) {
    return (
      <Redirect to="/enroll" />
    );
  }

  if (isMigrating) {
    return (
      <Redirect to="/migrate/payment-options" />
    );
  }

  // All enrollment steps have a progress bar except for maybe an account error page
  if (currentStep.progressBar) {
    const progressBarSteps = steps.filter(step => step.progressBar === currentStep.progressBar);
    const progressBarMilestones = progressBarSteps.filter(step => step.label);
    const labels = progressBarMilestones.map(form => form.label);

    status = (
      <StatusBar
        steps={dedupe(labels)}
        activeIndex={getLastMilestone(progressBarSteps, currentStep)}
        progress={currentStep.progressBar === 'activation'}
      />
    );
  }

  const Component = currentStep.component;

  const canGoBack = [
    EnrollmentSteps.ShippingAddress,
    EnrollmentSteps.BankAccount,
    EnrollmentSteps.Billing,
    EnrollmentSteps.Review,
  ].includes(currentStep.id);

  const canGoForward = [
    EnrollmentSteps.CustomerDetails,
    EnrollmentSteps.ShippingAddress,
    EnrollmentSteps.BankAccount,
    EnrollmentSteps.Billing,
  ].includes(currentStep.id) && (currentStepId !== latestStep.id);

  const payProgramLogo = payProgram === PayPrograms.Premium ? (
    <Image
      alt="GasBuddy+™ Premium Logo"
      src="https://static.gasbuddy.com/web/pay/svg/gasbuddy-plus-premium-logo-horizontal.svg"
      style={{ maxHeight: 60 }}
    />
  ) : (
    <Image
      alt="Pay with GasBuddy+™ Logo"
      src="https://static.gasbuddy.com/web/pay/svg/pwgb-plus-logo.svg"
      style={{ maxHeight: 60 }}
    />
  );


  const mainContent = (
    <Component
      basePath={basePath}
      goToStep={updateEditStep}
      program={payProgram}
      subheader={currentStep.subheader}
      analyticsScreenName={currentStep.analyticsScreenName}
    />
  );

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href={`//${payHost}${canonicalPath}`} />
      </Helmet>
      {currentStep.fullscreen !== true ? (
        <GradientContainer
          extra={currentStep.extra && React.createElement(currentStep.extra)}
          logo={payProgramLogo}
          onBackClick={canGoBack ? handleBackClick : undefined}
          onSkipClick={canGoForward ? handleSkipClicked : undefined}
          sidebar={status}
          shouldShowFooter={!currentStep.hideFootnote}
          title={currentStep.title}
        >
          {mainContent}
        </GradientContainer>
      ) : mainContent }
    </React.Fragment>
  );
}

Enrollment.propTypes = {
  basePath: PropTypes.string,
  declinedMembership: PropTypes.bool,
  failedIdentityVerification: PropTypes.bool,
  goToStep: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape(),
  }),
  login: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }),
  offeringId: PropTypes.number,
  payHost: PropTypes.string,
  promo: PropTypes.bool,
  stepId: PropTypes.string,
  upgraded: PropTypes.bool,
  wallet: WalletPropType,
  welcome: PropTypes.bool,
  hasPeriodicPaymentsSet: PropTypes.bool,
  hasSubmittedIdvCheckBefore: PropTypes.bool,
};

Enrollment.defaultProps = {
  basePath: '',
  declinedMembership: false,
  failedIdentityVerification: undefined,
  goToStep: () => { },
  location: {
    pathname: '',
  },
  login: false,
  match: {
    params: {},
  },
  offeringId: undefined,
  payHost: 'pay.gasbuddy.com',
  promo: false,
  stepId: undefined,
  upgraded: false,
  wallet: {},
  welcome: false,
  hasPeriodicPaymentsSet: false,
  hasSubmittedIdvCheckBefore: false,
};
