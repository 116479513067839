import React, { Fragment } from 'react';
import { Image, LineThrough } from '@gasbuddy/react-components';
import { AppBadges } from '@gasbuddy/react-consumer-components';
import classnames from 'classnames/bind';
import styles from './DownloadApp.module.css';

const cx = classnames.bind(styles);

export const APP_STORE_DATA = {
  name: 'iOSAppRating',
  rating: 4.5,
  reviewCount: '380,000+',
};

export const PLAY_STORE_DATA = {
  name: 'androidAppRating',
  rating: 4.5,
  reviewCount: '770,000+',
};

export default function DownloadApp() {
  return (
    <Fragment>
      <div className={cx('qrCodeContainer')}>
        <Image
          alt="Download App QR code"
          className={cx('qrCode')}
          src="https://static.gasbuddy.com/web/download-app-qr-code.svg"
        />
      </div>
      <LineThrough className={cx('orLineThrough')}>OR</LineThrough>
      <AppBadges
        className={cx('appBadges')}
        showReviewSections
        appStoreData={APP_STORE_DATA}
        playStoreData={PLAY_STORE_DATA}
      />
    </Fragment>
  );
}
