import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Image } from '@gasbuddy/react-components';
import ConfirmGasBackBankTransfer from '../../components/ConfirmGasBackBankTransfer';
import VerifyGasBackBankTransfer from '../../components/VerifyGasBackBankTransfer';
import GasBackBankTransferComplete from '../../components/GasBackBankTransferComplete';
import ConfirmGasBackCharityTransfer from '../../components/ConfirmGasBackCharityTransfer';
import ProcessingGasBackBankTransfer from '../../components/ProcessingGasBackBankTransfer';
import GasBackBankTransferForm from '../../components/GasBackBankTransferForm';
import GasBackCharitySelection from '../../components/GasBackCharitySelection';
import GasBackCharityTransferSuccess from '../../components/GasBackCharityTransferSuccess';
import ConfirmGasBackGiftCardDetails from '../../components/ConfirmGasBackGiftCardDetails';
import GasBackGiftCardSelect from '../../components/GasBackGiftCardSelect';
import GasBackTransferOptions from '../../components/GasBackTransferOptions';
import GradientContainer from '../../components/GradientContainer';
import GasBackGiftCardDetails from '../../components/GasBackGiftCardDetails';
import PrivateRoute from '../../components/PrivateRoute';
import ProcessingGasBackGiftCard from '../../components/ProcessingGasBackGiftCard';
import GasBackGiftCardPurchaseComplete from '../../components/GasBackGiftCardPurchaseComplete';

const removeTrailingSlash = str => str.replace(/\/$/, '');

export default function GasBackTransfer({ match }) {
  const basePath = removeTrailingSlash(match.url);

  return (
    <GradientContainer
      logo={(
        <Image src="https://static.gasbuddy.com/web/gas-buddy-logo-midnight-txt.svg" alt="GasBuddy" />
      )}
    >
      <Switch>
        <PrivateRoute
          exact
          path={basePath}
          component={GasBackTransferOptions}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank`}
          component={GasBackBankTransferForm}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/confirm`}
          component={ConfirmGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/processing`}
          component={ProcessingGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/verify`}
          component={VerifyGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/success`}
          component={GasBackBankTransferComplete}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard`}
          component={GasBackGiftCardSelect}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/details`}
          component={GasBackGiftCardDetails}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/confirm`}
          component={ConfirmGasBackGiftCardDetails}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/processing`}
          component={ProcessingGasBackGiftCard}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/success`}
          component={GasBackGiftCardPurchaseComplete}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity`}
          component={GasBackCharitySelection}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity/confirm`}
          component={ConfirmGasBackCharityTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity/success`}
          component={GasBackCharityTransferSuccess}
        />
        <Redirect to={basePath} />
      </Switch>
    </GradientContainer>
  );
}

GasBackTransfer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

GasBackTransfer.defaultProps = {
  match: {
    url: '',
  },
};
