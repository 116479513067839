import { ThemeProvider } from '@gasbuddy/react-components';
// import { config as FAConfig } from '@fortawesome/fontawesome-svg-core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route, Switch } from 'react-router-dom';
import { LOCALYTICS_CUSTOM_DIMENSIONS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import AnalyticsContext from '../../context/analytics';
import Application from '../Application';
import GasBackRedeem from '../../features/GasBackRedeem';
import GasBackTransfer from '../../features/GasBackTransfer';
import PrivateRoute from '../PrivateRoute';
import SwitchToNewProgramFlow from '../../features/SwitchToNewProgramFlow';
import RequireLogin from '../RequireLogin';
import logEvent from '../../../lib/utils/logEvent';
import useLocalytics from '../../hooks/useLocalytics';

// FAConfig.autoAddCss = false;

// TODO: Once Application uses GradientContainer, the GasBackTransfer route can go back inside Application
export function Router({
  accountId,
  localyticsId,
  localyticsDomain,
  memberName,
  recaptchaKey,
  isProduction,
}) {
  const analyticsConfig = useMemo(() => (
    typeof window !== 'undefined' && {
      localytics: {
        domain: localyticsDomain,
        key: localyticsId,
        allowLogs: !isProduction,
      },
    }
  ), [localyticsId, localyticsDomain, isProduction]);
  const analytics = useLocalytics(analyticsConfig);

  if (analytics) {
    analytics.on('event', (eventName, eventAttributes) => {
      logEvent(`Localytics:${eventName}`, eventAttributes);
    });

    if (accountId) {
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_ID, accountId);
    }

    if (memberName) {
      analytics.identifyUser({
        id: memberName,
      });
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_NAME, memberName);
    }
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <AnalyticsContext.Provider value={analytics}>
        <ThemeProvider theme="next">
          <Switch>
            <PrivateRoute path="/gasback/redeem" component={GasBackRedeem} />
            <Route path="/gasback/transfer" component={GasBackTransfer} />
            <Route
              path="/plus"
              render={routerProps => (
                <Application {...routerProps} program={PayPrograms.Plus} />
              )}
            />
            <Route
              path="/premium"
              render={routerProps => (
                <Application {...routerProps} program={PayPrograms.Premium} />
              )}
            />
            <Route
              exact
              path="/switch"
              render={() => (
                <RequireLogin returnPath="/switch">
                  <SwitchToNewProgramFlow />
                </RequireLogin>
              )}
            />
            <Route
              exact
              path="/switch/confirm"
              render={() => (
                <RequireLogin returnPath="/switch/confirm">
                  <SwitchToNewProgramFlow autoConfirm />
                </RequireLogin>
              )}
            />
            <Route path="/" component={Application} />
          </Switch>
        </ThemeProvider>
      </AnalyticsContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

Router.propTypes = {
  accountId: PropTypes.number,
  isProduction: PropTypes.bool,
  localyticsId: PropTypes.string,
  localyticsDomain: PropTypes.string,
  memberName: PropTypes.string,
  recaptchaKey: PropTypes.string,
};

Router.defaultProps = {
  accountId: undefined,
  isProduction: true,
  localyticsId: undefined,
  localyticsDomain: 'gasbuddy.com',
  memberName: undefined,
  recaptchaKey: undefined,
};
