import React, { useCallback, useState } from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import CheckingAccountManual from '../CheckingAccountManual';
import CheckingAccountWelcome from '../CheckingAccountWelcome';

const VARIANTS = ['manual', 'automatic'];

export default function CheckingAccountForm() {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_ENROLL_BANK_START);

  const [variant, setVariant] = useState(); // 'automatic' or 'manual'

  const chooseVariant = useCallback(v => setVariant(VARIANTS.includes(v) ? v : undefined), []);
  const switchToManual = useCallback(() => chooseVariant('manual'), [chooseVariant]);

  switch (variant) {
    case 'manual': {
      return (<CheckingAccountManual />);
    }

    default: {
      return (<CheckingAccountWelcome switchToManual={switchToManual} />);
    }
  }
}
