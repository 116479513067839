import { connect } from 'react-redux';
import CardActivationForm from './CardActivationForm';
import { activateCard } from '../../reducers/actions';
import { getPayCard } from '../../../lib/utils/cardInstruments';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ wallet, instruments, config }) {
  const { cardActivateError, isActivatingCard } = instruments;

  return {
    card: getPayCard(wallet.status?.instruments),
    consumerHost: config.consumerHost,
    error: getErrorMessage(cardActivateError),
    hasEsigned: !!wallet.status?.esign,
    isSaving: isActivatingCard,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(activateCard(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardActivationForm);
