import { connect } from 'react-redux';
import PaymentSubmitted from './PaymentSubmitted';

function mapStateToProps({ config }) {
  return {
    consumerHost: config.consumerHost,
  };
}

export default connect(mapStateToProps)(PaymentSubmitted);
