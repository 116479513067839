import { connect } from 'react-redux';
import Summary from './CustomerDetailsSummary';

const mapStateToProps = ({ wallet }) => ({
  firstName: wallet.status?.name?.first_name,
  lastName: wallet.status?.name?.last_name,
  state: wallet.status?.shipping_address?.region,
});

const CustomerDetailsSummary = connect(mapStateToProps)(Summary);
export default CustomerDetailsSummary;
