import { IdentityVerificationStatus } from '../constants';
import { Action } from './actions';

export const defaultState = {
  didConfirmMicrodeposits: false,
  isConfirmingDeposits: false,
  depositConfirmError: undefined,
  isActivatingCard: false,
  cardActivateError: undefined,
  isShippingCard: false,
  cardShipError: undefined,
  hasSubmittedIdvCheckBefore: undefined,
  failedIdentityVerification: undefined,
  canRetryIdentityVerification: undefined,
};

export default function instrumentsReducer(state = defaultState, action = {}) {
  const body = action.payload || {};

  switch (action.type) {
    case Action.Microdeposits.ConfirmPending:
      return {
        ...state,
        isConfirmingDeposits: true,
        depositConfirmError: undefined,
      };

    case Action.Microdeposits.ConfirmCompleted:
      return {
        ...state,
        didConfirmMicrodeposits: true,
        isConfirmingDeposits: false,
      };

    case Action.Microdeposits.ConfirmFailed:
      return {
        ...state,
        isConfirmingDeposits: false,
        depositConfirmError: body,
      };

    case Action.Activation.ActivateCardPending:
    case Action.Activation.ActivateMigratingCardPending:
      return {
        ...state,
        isActivatingCard: true,
        cardActivateError: undefined,
      };

    case Action.Activation.ActivateCardCompleted:
    case Action.Activation.ActivateMigratingCardCompleted:
      return {
        ...state,
        isActivatingCard: false,
      };

    case Action.Activation.ActivateCardFailed:
    case Action.Activation.ActivateMigratingCardFailed:
      return {
        ...state,
        isActivatingCard: false,
        cardActivateError: body,
      };

    case Action.Ship.ShipCardPending:
      return {
        ...state,
        isShippingCard: true,
        cardShipError: undefined,
      };

    case Action.Ship.ShipCardCompleted:
      return {
        ...state,
        hasSubmittedIdvCheckBefore: true,
        failedIdentityVerification: false,
        isShippingCard: false,
      };

    case Action.Ship.ShipCardFailed:
      return {
        ...state,
        isShippingCard: false,
        cardShipError: body,
        hasSubmittedIdvCheckBefore: true,
        failedIdentityVerification: Object.values(IdentityVerificationStatus).includes(body.response?.code),
        canRetryIdentityVerification: body.response?.code === IdentityVerificationStatus.TryAgain,
      };

    case Action.IdentityCheck.ClearResult:
      delete state.cardShipError;
      delete state.failedIdentityVerification;
      delete state.canRetryIdentityVerification;
      delete state.hasSubmittedIdvCheckBefore;
      return state;

    default:
      return state;
  }
}
