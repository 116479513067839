import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WelcomeForm from './WelcomeForm';
import PayPrograms from '../../constants/payPrograms';
import formatOfferDetails from '../../../lib/utils/formatOfferDetails';

function mapStateToProps({ auth, membership, progress }, { program }) {
  const { email, postal } = progress;
  const { account_id: accountId, error } = auth;

  const {
    plus: {
      monthly: monthlyPlusOffer,
    },
    premium: {
      monthly: monthlyPremiumOffer,
    },
  } = membership;

  let maxCentsOff;
  let initialPrice;

  if (program === PayPrograms.Plus) {
    ({ maxCentsOff, initialPrice } = formatOfferDetails(monthlyPlusOffer));
  } else if (program === PayPrograms.Premium) {
    ({ maxCentsOff, initialPrice } = formatOfferDetails(monthlyPremiumOffer));
  }

  return {
    email,
    error,
    isLoggedIn: !!accountId,
    maxCentsOff,
    postal,
    price: initialPrice,
  };
}

export default withRouter(connect(mapStateToProps)(WelcomeForm));
