import { connect } from 'react-redux';
import { Router } from './Router';

function mapStateToProps({ auth, config }) {
  const { localyticsId, localyticsDomain, recaptchaSiteKey } = config;
  const { account_id: accountId, membername } = auth;

  return {
    accountId,
    localyticsId,
    localyticsDomain,
    memberName: membername,
    recaptchaKey: recaptchaSiteKey,
    isProduction: config.env === 'production',
  };
}

export default connect(mapStateToProps)(Router);
