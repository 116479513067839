import { connect } from 'react-redux';
import PaymentOptionsSummary from './PaymentOptionsSummary';

function mapStateToProps({ progress }) {
  return {
    paymentOption: progress.paymentOption,
  };
}

export default connect(mapStateToProps)(PaymentOptionsSummary);
