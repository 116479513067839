const EnrollmentSteps = {
  Esign: 'esign',
  ShippingAddress: 'shipping',
  CustomerDetails: 'customer-details',
  BankAccount: 'bank',
  Billing: 'billing',
  Review: 'review',
  Enrolled: 'enrolled',
};

export default EnrollmentSteps;
