import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import { loginWithCode, loginWithGBAccount, requestMagicLink } from '../../reducers/actions';

function mapStateToProps({ auth, config, magiclinks, progress, social }, ownProps) {
  return {
    email: progress.email || social.email,
    identityHost: config.identityHost,
    isLoggingIn: auth.isLoggingIn,
    payHost: config.payHost,
    isSigningUp: auth.isSigningUp,
    error: getErrorMessage(auth.error),
    magicLinkError: getErrorMessage(magiclinks.magicLinkError),
    magicLinkLoading: magiclinks.isLoading,
    magicLinkStatus: magiclinks.modalVariant,
    magicLinkDevice: magiclinks.magicLinkDevice,
    postalCode: progress.postal,
    returnPath: ownProps.returnPath || (progress.skipWelcome ? '/enroll' : undefined),
    signupError: auth.signupError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginWithGBAccount: (body, identityHost, analyticsContext) => dispatch(loginWithGBAccount(body, identityHost, analyticsContext)),
    requestMagicLink: (body, identityHost) => dispatch(requestMagicLink(body, identityHost)),
    loginWithCode: (body, identityHost) => dispatch(loginWithCode(body, identityHost)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
