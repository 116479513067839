import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Text } from '@gasbuddy/react-components';
import styles from './SupportLink.module.css';

const cx = classnames.bind(styles);

export default function SupportLink({ children, className, ...rest }) {
  return (
    <Text
      as="a"
      href="https://help.gasbuddy.com/hc/en-us/requests/new"
      target="_blank"
      rel="noopener noreferrer"
      className={cx('supportLink', className)}
      {...rest}
    >
      {children}
    </Text>
  );
}

SupportLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SupportLink.defaultProps = {
  children: undefined,
  className: undefined,
};
