import React, { useEffect } from 'react';
import { Loader } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';

export default function SavingsPage({ consumerHost }) {
  useEffect(() => {
    window.location.href = `//${consumerHost}/account/savings`;
  }, [consumerHost]);

  return (
    <Loader size="lg" />
  );
}

SavingsPage.propTypes = {
  consumerHost: PropTypes.string,
};

SavingsPage.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
