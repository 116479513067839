import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import Enrollment from '../../features/Enrollment';
import DefaultHelmet from '../DefaultHelmet';
import PayProgramContext from '../../context/payProgram';
import PayPrograms from '../../constants/payPrograms';
import RenewCardFlow from '../../features/RenewCardFlow';
import RequireLogin from '../RequireLogin';
import ReturnToSenderFlow from '../../features/ReturnToSenderFlow';
import ProgramPropType from '../../prop-types/program';
import UpdatePaymentOptionsFlow from '../../features/UpdatePaymentOptionsFlow';
import PaymentOptionUpdateSuccess from '../PaymentOptionUpdateSuccess';
import PaymentSubmitted from '../PaymentSubmitted';
import PaymentError from '../PaymentError';
import MigrateCardFlow from '../../features/MigrateCardFlow';
import SwitchToAutoPay from '../SwitchToAutoPay';
import SwitchToAutoPaySuccess from '../SwitchToAutoPaySuccess';
import '../../styles/variables.css';

const removeTrailingSlash = str => str.replace(/\/$/, '');

// TODO: Refactor this component to use GradientContainer
export default function Application({ match, program }) {
  const basePath = removeTrailingSlash(match.url);

  return (
    <PayProgramContext.Provider value={program}>
      <DefaultHelmet program={program} />
      <Switch>
        <Route
          exact
          path={`${basePath}/`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              welcome
            />
          )}
        />
        <Route
          exact
          path={`${basePath}/promo`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              promo
            />
          )}
        />
        <Route
          exact
          path={`${basePath}/start`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              login
            />
          )}
        />
        <Route
          exact
          path="/renew"
          render={() => (
            <RequireLogin returnPath="/renew">
              <RenewCardFlow />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/renew/confirm"
          render={({ location }) => (
            <RequireLogin allowGuid location={location} returnPath="/renew/confirm">
              <RenewCardFlow autoConfirm />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/reship"
          render={() => (
            <RequireLogin returnPath="/reship">
              <ReturnToSenderFlow />
            </RequireLogin>
          )}
        />
        <Route
          path={`${basePath}/enroll/:step?`}
          render={routerProps => (
            <RequireLogin>
              <Enrollment
                {...routerProps}
                basePath={basePath}
              />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options"
          render={() => (
            <RequireLogin returnPath="/payment-options">
              <UpdatePaymentOptionsFlow />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options/success"
          render={() => (
            <RequireLogin>
              <PaymentOptionUpdateSuccess />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options/pay-now/success"
          render={() => (
            <RequireLogin>
              <PaymentSubmitted />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options/pay-now/error"
          render={() => (
            <RequireLogin>
              <PaymentError />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options/switch-to-autopay"
          render={() => (
            <RequireLogin>
              <SwitchToAutoPay />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/payment-options/switch-to-autopay/success"
          render={() => (
            <RequireLogin>
              <SwitchToAutoPaySuccess />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path={`${basePath}/migrate/:step?`}
          render={routerProps => (
            <RequireLogin>
              <MigrateCardFlow
                {...routerProps}
                basePath={basePath}
              />
            </RequireLogin>
          )}
        />
        <Redirect to="/" />
      </Switch>
    </PayProgramContext.Provider>
  );
}

Application.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  program: ProgramPropType,
};

Application.defaultProps = {
  match: {
    url: '',
  },
  program: PayPrograms.Free,
};
