import { connect } from 'react-redux';
import PaymentOptions from './PaymentOptions';
import { updateCurrentPaymentOption, updatePaymentOptionForPendingEnrollment } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ progress, config }) {
  return {
    consumerHost: config.consumerHost,
    paymentOption: progress.paymentOption,
    canSwitchPaymentOption: progress.canSwitchPaymentOption,
    isSavingPaymentOption: progress.isLoading,
    updatedPaymentOption: progress.updatedPaymentOption,
    error: getErrorMessage(progress.error, 'There was an error updating your payment option. Please try again later.'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePaymentOptionForPendingEnrollment: (e, body) => {
      e.preventDefault();
      dispatch(updatePaymentOptionForPendingEnrollment(body));
    },
    updateCurrentPaymentOption: (e, body) => {
      e.preventDefault();
      dispatch(updateCurrentPaymentOption(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptions);
