import { FlexGrid, Image, Text } from '@gasbuddy/react-components';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './GetStarted.module.css';

const cx = classnames.bind(styles);

const ENROLLMENT_STEPS_SUMMARY = [
  {
    icon: '//static.gasbuddy.com/web/icons/pwgb-avatar-icon.svg',
    iconAlt: 'Customer',
    title: 'Customer Details',
    description: 'We need your legal name, driver\'s license, and income information to verify your identity and enable access to your card.',
  },
  {
    icon: '//static.gasbuddy.com/web/icons/pwgb-home-icon.svg',
    iconAlt: 'Address',
    title: 'Shipping Address',
    description: 'We need your shipping address so we know where to send your new Pay with GasBuddy+™ card.',
  },
  {
    icon: '//static.gasbuddy.com/web/icons/pwgb-bank-icon.svg',
    iconAlt: 'Bank',
    title: 'Securely link your checking account',
    description: 'We\'ll connect it to your Pay with GasBuddy+™ card so you can start saving on fuel.',
  },
  {
    icon: '//static.gasbuddy.com/web/icons/pwgb-clock-icon.svg',
    iconAlt: 'Payment Options',
    title: 'Payment Options',
    description: 'Select your preferred ACH payment method.',
  },
];

const [CustomerDetailsStep, ShippingAddressStep, BankStep, PaymentOptionsStep] = ENROLLMENT_STEPS_SUMMARY;

const EnrollmentStep = ({ icon, iconAlt, title, description }) => (
  <div className={cx('enrollmentStepOverview')}>
    <div className={cx('enrollmentStepIconContainer')}>
      <Image
        src={icon}
        alt={iconAlt}
        className={cx('enrollmentStepIcon')}
      />
    </div>
    <div className={cx('enrollmentStepSummary')}>
      <Text bold color="midnight">
        {title}
      </Text>
      <Text as="p">
        {description}
      </Text>
    </div>
  </div>
);
EnrollmentStep.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default function EnrollmentStepsOverview() {
  return (
    <React.Fragment>
      <Text as="p">
        You are steps away from unlocking major savings on every gallon.
        We just need a few more details from you before we can ship your Pay with GasBuddy+<sup>&trade;</sup> card:
      </Text>
      <FlexGrid container>
        <FlexGrid.Column tablet={6}>
          <EnrollmentStep
            icon={CustomerDetailsStep.icon}
            iconAlt={CustomerDetailsStep.iconAlt}
            title={CustomerDetailsStep.title}
            description={CustomerDetailsStep.description}
          />
          <EnrollmentStep
            icon={ShippingAddressStep.icon}
            iconAlt={ShippingAddressStep.iconAlt}
            title={ShippingAddressStep.title}
            description={ShippingAddressStep.description}
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6}>
          <EnrollmentStep
            icon={BankStep.icon}
            iconAlt={BankStep.iconAlt}
            title={BankStep.title}
            description={BankStep.description}
          />
          <EnrollmentStep
            icon={PaymentOptionsStep.icon}
            iconAlt={PaymentOptionsStep.iconAlt}
            title={PaymentOptionsStep.title}
            description={PaymentOptionsStep.description}
          />
        </FlexGrid.Column>
      </FlexGrid>
    </React.Fragment>
  );
}
