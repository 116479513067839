import React, { useEffect, useState } from 'react';
import { Header, Loader } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import Container from '../../components/Container';
import SwitchToNewProgramForm from '../../components/SwitchToNewProgramForm';
import Panel from '../../components/Panel';
import SwitchSuccessful from './SwitchSuccessful';
import ErrorSwitchingCard from './ErrorSwitchingCard';
import SwitchIneligible from './SwitchIneligible';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

function SwitchToNewProgramFlow({
  autoConfirm,
  canSwitch,
  consumerHost,
  error,
  isSwitching,
  requestNewProgramCard,
  wasSwitched,
}) {
  const [requestProcessed, setRequestProcessed] = useState(false);
  const shouldAutoSwitch = !!(canSwitch && !wasSwitched && autoConfirm && requestNewProgramCard);
  const analyticsScreenName = autoConfirm
    ? ANALYTICS_SCREENS.PAYPLUS_MIGRATE_REVIEW_ADDRESS
    : ANALYTICS_SCREENS.PAYPLUS_MIGRATE_CHANGE_ADDRESS;
  useTracking(analyticsScreenName);

  useEffect(() => {
    if (shouldAutoSwitch) {
      requestNewProgramCard();
    }
  }, [requestNewProgramCard, shouldAutoSwitch]);

  useEffect(() => {
    if (canSwitch && !isSwitching && !error && wasSwitched) {
      setRequestProcessed(true);
    }
  }, [canSwitch, isSwitching, error, wasSwitched]);

  if (requestProcessed) {
    return (
      <SwitchSuccessful consumerHost={consumerHost} />
    );
  }

  if (error) {
    return (
      <ErrorSwitchingCard consumerHost={consumerHost} />
    );
  }

  if (!canSwitch) {
    return (
      <SwitchIneligible consumerHost={consumerHost} />
    );
  }

  if (shouldAutoSwitch) {
    return (
      <Container sidebar={false}>
        <Panel>
          <Loader>Requesting New Card...</Loader>
        </Panel>
      </Container>
    );
  }

  return (
    <Container sidebar={false}>
      <Panel>
        <Header as="h2">Change Address</Header>
        <SwitchToNewProgramForm />
      </Panel>
    </Container>
  );
}

SwitchToNewProgramFlow.propTypes = {
  autoConfirm: PropTypes.bool,
  canSwitch: PropTypes.bool,
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  isSwitching: PropTypes.bool,
  requestNewProgramCard: PropTypes.func,
  wasSwitched: PropTypes.bool,
};

SwitchToNewProgramFlow.defaultProps = {
  autoConfirm: false,
  canSwitch: false,
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  isSwitching: false,
  requestNewProgramCard: undefined,
  wasSwitched: false,
};

export default SwitchToNewProgramFlow;
