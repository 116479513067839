import { connect } from 'react-redux';
import MigratingCardPaymentOptions from './MigratingCardPaymentOptions';
import { fetchCurrentPaymentOption } from '../../reducers/actions';

function mapStateToProps({ progress }) {
  return {
    paymentOption: progress.paymentOption || 'autopay',
    isLoading: progress.isLoading,
    error: progress.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPaymentOption: () => dispatch(fetchCurrentPaymentOption()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MigratingCardPaymentOptions);
