import { Actions, Constraint, FlexGrid, Form, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { VALID_INTEGER_REGEX } from '../../../lib/utils/isValidInteger';
import InstrumentPropType from '../../prop-types/instrument';
import CardActivationHeader from '../CardActivationHeader';
import FormActionButtons from '../FormActionButtons';
import InputWithValidation from '../InputWithValidation';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function CardActivationForm({
  card,
  consumerHost,
  hasEsigned,
  error,
  isSaving,
  onSubmit,
}) {
  const defaultFormProperties = {
    last4: '',
  };
  const [formValues, setFormValues] = React.useState(defaultFormProperties);
  const [formErrors, setFormErrors] = React.useState(defaultFormProperties);

  useTracking(ANALYTICS_SCREENS.PAYPLUS_ACTIVATE_CONFIRM_DIGITS);

  const handleFieldChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }, [formErrors, formValues]);

  const handleSubmit = React.useCallback((e) => {
    let isValid = true;
    const newFormErrors = { ...defaultFormProperties };

    if (formValues.last4.length !== 4) {
      newFormErrors.last4 = 'Please enter 4 digits';
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      e.preventDefault();
    } else {
      setFormErrors(defaultFormProperties);
      onSubmit(e, {
        ...formValues,
        instrumentId: card.instrument_id,
      });
    }
  }, [defaultFormProperties, onSubmit, formValues, card.instrument_id]);

  if (!hasEsigned) {
    return (
      <Constraint desktop={10}>
        <CardActivationHeader title="Activate your card to start saving" />
        <br />
        <Text as="p" color="orange">
          Sorry, your card cannot be activated. Please contact support for assistance.
        </Text>
        <br />
        <br />
        <Actions>
          <Actions.Button
            as="a"
            href="https://help.gasbuddy.com/hc/en-us/articles/360022898273-Editing-your-GasBuddy-profile#h_3f59581c-2616-4ddb-993d-8c99d2ecb67a"
            target="_blank"
            primary
            tablet={6}
            desktop={5}
          >
            Contact Support
          </Actions.Button>
          <Actions.Button
            as="a"
            href={`//${consumerHost}/account/savings`}
            secondary
            type="submit"
            tablet={6}
            desktop={5}
          >
            Go To Savings
          </Actions.Button>
        </Actions>
      </Constraint>
    );
  }

  return (
    <Form aria-label="Card Activation Form" action="/activate" method="post" loading={isSaving} onSubmit={handleSubmit}>
      <Constraint desktop={10}>
        <InputWithValidation type="hidden" name="instrumentId" value={card.instrument_id} />
        <InputWithValidation type="hidden" name="esign" value={hasEsigned} />
        <CardActivationHeader title="Activate your card to start saving" />
        <br />
        <Text as="p">Before you can start using your card, please enter the last 4 digits of your Pay with GasBuddy+™ card.</Text>
        <br />
        {!!error && (
          <Text as="p" color="orange">
            {error}
          </Text>
        )}
        <FlexGrid container>
          <FlexGrid.Column tablet={6}>
            <InputWithValidation
              mask={VALID_INTEGER_REGEX}
              name="last4"
              label="Last 4 digits"
              onChange={handleFieldChange}
              isValid={formErrors.last4 ? false : undefined}
              error={formErrors.last4}
              required
            />
          </FlexGrid.Column>
        </FlexGrid>
        <br />
        <FormActionButtons canSubmit={!isSaving} primaryButtonText="Activate Now" />
      </Constraint>
    </Form>
  );
}

CardActivationForm.propTypes = {
  card: InstrumentPropType.isRequired,
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  hasEsigned: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
};

CardActivationForm.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  hasEsigned: true,
  isSaving: false,
  onSubmit: () => {},
};
