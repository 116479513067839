import { Actions, Form, Input, Text, Button } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import StripeCheckout from 'react-stripe-checkout';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import { PlusPoints, PremiumPoints } from '../../constants/membership';
import PayPrograms from '../../constants/payPrograms';
import useTracking from '../../hooks/useTracking';
import ProgramPropType from '../../prop-types/program';
import FormActionButtons from '../FormActionButtons';
import MembershipOption from '../MembershipOption';
import PassengerVehicleCheckbox from '../PassengerVehicleCheckbox';
import TermsOfService from '../TermsOfService';
import sharedStyles from '../../styles/shared.module.css';

const cx = classnames.bind(sharedStyles);

export default function MembershipUpgrade({
  email,
  error,
  isLoading,
  offeringId: initialOfferingId,
  onSubmit,
  options,
  program,
  stripeToken,
}) {
  const { trackEvent, trackPageView } = useTracking(ANALYTICS_SCREENS.PAYPLUS_PREMIUM_UPGRADE);

  const [offeringId, setOfferingId] = useState(initialOfferingId);
  const [passengerVehicle, setPassengerVehicle] = useState(false);
  const selectedOffer = options.find(o => o.plan_offering_id === offeringId);
  const hasRoadside = selectedOffer?.has_roadside;
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [clientError, setClientError] = useState();

  const handleTermsChecked = useCallback((isAccepted) => {
    setAcceptedTerms(isAccepted);
  }, []);

  const programPoints = program === PayPrograms.Plus ? PlusPoints : PremiumPoints;

  const handleOfferChange = useCallback((id) => {
    setOfferingId(id);
  }, []);

  const handlePassengerVehicleChange = useCallback((e) => {
    const { checked } = e.target;
    setPassengerVehicle(checked);
  }, []);

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_ENROLL_CONFIRM_SUBMIT_TAPPED, {
      AcceptedTerms: acceptedTerms,
    });

    if (acceptedTerms) {
      onSubmit(e, { acceptedTerms, offeringId });
    } else {
      e.preventDefault();
      setClientError('You must accept the terms of service');
    }
  }, [acceptedTerms, offeringId, onSubmit, trackEvent]);

  const handleStripeModalOpened = useCallback(() => {
    trackPageView(ANALYTICS_SCREENS.PAYPLUS_PREMIUM_PURCHASE_CREDIT_CARD);
  }, [trackPageView]);

  const handleStripeComplete = useCallback((token) => {
    onSubmit(null, { acceptedTerms, offeringId, token });
  }, [acceptedTerms, offeringId, onSubmit]);

  let actions;
  const needsToAcceptRoadsideTerms = hasRoadside && !passengerVehicle;
  const disableSubmit = !acceptedTerms || needsToAcceptRoadsideTerms || isLoading;

  if (selectedOffer?.period_type === 'year') {
    actions = (
      <Actions>
        <Actions.Button
          disabled={disableSubmit}
          primary
          fluid
          loading={isLoading}
          type="submit"
          tablet={5}
          desktop={4}
        >
          Charge Bank Account
        </Actions.Button>
        <Actions.Action tablet={5} desktop={4}>
          <StripeCheckout
            ComponentClass="aside"
            allowRememberMe
            name={`GasBuddy ${program}`}
            description="1 Year Subscription"
            currency="USD"
            email={email}
            amount={parseFloat(selectedOffer.renewal_price) * 100} // Stripe wants value in cents
            token={handleStripeComplete}
            stripeKey={stripeToken}
            opened={handleStripeModalOpened}
            disabled={disableSubmit}
          >
            <Button
              secondary
              fluid
              disabled={disableSubmit}
              loading={isLoading}
              type="button" // Prevent form submission if JS disabled b/c it will charge bank
              className={cx('payWithCreditCard')}
            >
              Pay With Credit Card
            </Button>
          </StripeCheckout>
        </Actions.Action>
      </Actions>
    );
  } else {
    actions = (
      <FormActionButtons primaryButtonText="Purchase" canSubmit={!disableSubmit} />
    );
  }

  return (
    <Form action="/upgrade" method="post" onSubmit={handleSubmit}>
      <Input type="hidden" name="offeringId" value={offeringId} />
      <Text as="p">
        Choose between monthly or annual billing. Monthly billing charges your bank account each month. Annual billing charges your bank once a year.
      </Text>
      <ul>
        {programPoints.map(point => (
          <li key={point.key}>{point.description}</li>
        ))}
      </ul>
      <br />
      {options.map(offer => (
        <MembershipOption
          key={offer.plan_offering_id}
          id={offer.plan_offering_id}
          isSelected={offeringId === offer.plan_offering_id}
          title={`${program} ${offer.period_type === 'month' ? 'Monthly' : 'Annual'} Billing`}
          onSelect={handleOfferChange}
          periodType={offer.period_type}
          price={offer.renewal_price}
        />
      ))}
      <br />
      {hasRoadside && (
        <React.Fragment>
          <br />
          <PassengerVehicleCheckbox onChange={handlePassengerVehicleChange} />
        </React.Fragment>
      )}
      <TermsOfService buttonText="Purchase" onCheck={handleTermsChecked} />
      {!!(clientError || error) && (
        <Text as="p" color="orange">
          {clientError || error}
        </Text>
      )}
      {actions}
    </Form>
  );
}

MembershipUpgrade.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  offeringId: PropTypes.number,
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    plan_offering_id: PropTypes.number,
    has_roadside: PropTypes.bool,
    period_type: PropTypes.oneOf(['month', 'year']),
    renewal_price: PropTypes.string,
  })),
  program: ProgramPropType,
  stripeToken: PropTypes.string.isRequired,
};

MembershipUpgrade.defaultProps = {
  error: undefined,
  isLoading: false,
  offeringId: undefined,
  onSubmit: () => {},
  options: [],
  program: PayPrograms.Premium,
};
