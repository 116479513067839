import { Constraint, Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import Panel from '../Panel';
import PwGBCardImage from '../PwGBCardImage';

export default function NewCardSuccess({
  container: Container,
  header,
  renewed,
  subheader,
  analyticsEvent,
}) {
  useTracking(analyticsEvent);

  const nextStepsPoints = [
    'Your new card will ship in 3-5 business days.',
    'It should arrive in about 7-10 business days.',
  ];

  if (renewed) {
    nextStepsPoints.push(
      'You can start using your new card right away. No activation is necessary',
    );
  }

  return (
    <Container>
      <Constraint desktop={10}>
        <PwGBCardImage />
        <Header as="h2">{header}</Header>
        <Text as="p" elongated size="lg" uppercase>{subheader}</Text>
        <Text as="p" bold>Here’s what happens next:</Text>
        <ul>
          {nextStepsPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      </Constraint>
    </Container>
  );
}

NewCardSuccess.propTypes = {
  container: PropTypes.elementType,
  analyticsEvent: PropTypes.string,
  header: PropTypes.string,
  renewed: PropTypes.bool,
  subheader: PropTypes.string,
};

NewCardSuccess.defaultProps = {
  container: Panel,
  analyticsEvent: ANALYTICS_SCREENS.PAYPLUS_EXPIRED_CARD_ADDRESS_CONFIRMED,
  header: 'Success!',
  renewed: false,
  subheader: 'Your new card request is processing.',
};
