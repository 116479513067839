import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@gasbuddy/react-components';

export default function CustomerDetailsSummary({
  firstName,
  lastName,
  state,
}) {
  const hasAllInfo = !!firstName && !!lastName && !!state;

  // NOTE: The original mockup described to show date of birth and household income as well
  // However, we do not keep that information in wallet for security reasons, hence showing only insensitive info here
  if (hasAllInfo) {
    return (
      <Text as="p">
        {firstName} {lastName}
        <br />
        {state}
      </Text>
    );
  }

  return null;
}

CustomerDetailsSummary.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  state: PropTypes.string,
};

CustomerDetailsSummary.defaultProps = {
  firstName: undefined,
  lastName: undefined,
  state: undefined,
};
