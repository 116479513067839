import { connect } from 'react-redux';
import GetStarted from './GetStarted';
import { recordEsignConsent } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ wallet, config }) {
  const { isLoading, error } = wallet;
  return {
    consumerHost: config.consumerHost,
    isSavingConsent: isLoading,
    error: getErrorMessage(error),
    esigned: wallet.status?.esign,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recordEsignConsent: (e) => {
      e.preventDefault();
      dispatch(recordEsignConsent());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);
