import { Button, Checkbox, Header, Link, Modal, Text } from '@gasbuddy/react-components';
import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import styles from './EsignTermsCheckbox.module.css';
import LINKS from '../../constants/links';

const cx = classnames.bind(styles);

export default function EsignTermsCheckbox({ onTermsChecked }) {
  const [shouldShowConsentModal, setShouldShowConsentModal] = useState(false);

  const handleTermsChecked = useCallback((e) => {
    onTermsChecked(e);
  }, [onTermsChecked]);

  const showConsentModal = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShouldShowConsentModal(true);
  }, []);

  const handleCloseConsentModal = useCallback(() => {
    setShouldShowConsentModal(false);
  }, []);

  return (
    <div className={cx('esignContainer')}>
      <Modal
        size="md"
        content={() => (
          <Fragment>
            <Header as="h3">E-Sign Consent</Header>
            <Text as="p">
              You have the right to withdraw your consent at any time.
              Withdrawal of consent may result in the termination of your access to part or all of our Services.
              Learn more by reading our <Link bold href={LINKS.GB_TERMS} target="_blank" rel="noopener noreferrer">Terms of Service</Link>.
            </Text>
            <Button primary wide onClick={handleCloseConsentModal}>OK</Button>
          </Fragment>
        )}
        onClose={handleCloseConsentModal}
        forceIsShowing={shouldShowConsentModal}
      />
      <Checkbox
        id="esignTermsAcceptance"
        name="esign"
        className={cx('checkbox')}
        onClick={handleTermsChecked}
        label={(
          <Text>
            I acknowledge that I have read and agree to the terms outlined in the
            &nbsp;
            <Link bold href={LINKS.GB_TERMS} target="_blank" rel="noopener noreferrer">E-Sign Consent Terms</Link>
            &nbsp;
            <FontAwesomeIcon
              icon={faInfoCircle}
              onClick={showConsentModal}
              className={cx('infoIcon')}
            />
          </Text>
        )}
      />
    </div>
  );
}

EsignTermsCheckbox.propTypes = {
  onTermsChecked: PropTypes.func,
};

EsignTermsCheckbox.defaultProps = {
  onTermsChecked: () => {},
};
