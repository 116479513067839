import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Header, Text, Constraint, Actions } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import Container from '../../components/Container';
import ReshipCardForm from '../../components/ReshipCardForm';
import NewCardSuccess from '../../components/NewCardSuccess';
import Panel from '../../components/Panel';
import AddressPropType from '../../prop-types/address';

function ReturnToSenderIntro({ address, onContinue }) {
  return (
    <Panel>
      <Constraint tablet={8}>
        <Header as="h2">Please update your address.</Header>
        <Text as="p">
          We received your card as a return to sender and are unable to send to the address below.
          Please update your address and we will get a new card shipped to you.
        </Text>
      </Constraint>
      <br />
      <br />
      {!!address && (
        <Text as="p" bold>
          {address.line_1}
          {!!address.line_2 && ` ${address.line_2}`}
          <br />
          {address.locality}, {address.region} {address.postal_code}
        </Text>
      )}
      <br />
      <Actions>
        <Actions.Button
          fluid
          onClick={onContinue}
          type="submit"
          primary
        >
          Update Address
        </Actions.Button>
      </Actions>
    </Panel>
  );
}

ReturnToSenderIntro.propTypes = {
  address: AddressPropType,
  onContinue: PropTypes.func,
};

ReturnToSenderIntro.defaultProps = {
  address: undefined,
  onContinue: () => {},
};

function ReturnToSenderFlow({
  address,
  isOverdue,
  wasReshipped,
}) {
  const [clickedContinue, setClickedContinue] = useState(false);

  const handleContinue = useCallback((e) => {
    e.preventDefault();
    setClickedContinue(true);
  }, []);

  if (!isOverdue && !wasReshipped) {
    return (
      <Redirect to="/enroll" />
    );
  }

  let content;

  if (wasReshipped) {
    content = (
      <NewCardSuccess />
    );
  } else if (!clickedContinue) {
    content = (
      <ReturnToSenderIntro
        address={address}
        onContinue={handleContinue}
      />
    );
  } else {
    content = (
      <Panel>
        <Header as="h2">Shipping Address</Header>
        <ReshipCardForm isLoggedIn />
      </Panel>
    );
  }

  return (
    <Container>{content}</Container>
  );
}

ReturnToSenderFlow.propTypes = {
  address: AddressPropType,
  isOverdue: PropTypes.bool,
  wasReshipped: PropTypes.bool,
};

ReturnToSenderFlow.defaultProps = {
  address: undefined,
  isOverdue: false,
  wasReshipped: false,
};

export default ReturnToSenderFlow;
