import { connect } from 'react-redux';
import Enrolled from './Enrolled';
import hasUsedIav from '../../../lib/utils/hasUsedIav';

function mapStateToProps({ auth, config, wallet }) {
  const { hasRedirect } = auth;
  const { instruments } = wallet;

  return {
    hasRedirect,
    payHost: config.payHost,
    withIAV: hasUsedIav(instruments),
  };
}

export default connect(mapStateToProps)(Enrolled);
