import React, { Fragment, useCallback, useEffect } from 'react';
import { Button, FlexGrid, Form, Header, Text } from '@gasbuddy/react-components';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '../Container';
import Panel from '../Panel';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import PwGBCardImage from '../PwGBCardImage';
import formatPrice from '../../../lib/utils/formatPrice';

export default function SwitchToAutoPay({
  amountOwed,
  canSwitchPaymentOption,
  consumerHost,
  error,
  fetchCurrentPaymentOption,
  isSwitchingPaymentOption,
  paymentOption,
  statementNotYetGenerated,
  switchedPaymentOption,
  switchToAutoPayOption,
}) {
  const handleSwitchToAutoPay = useCallback((e) => {
    switchToAutoPayOption(e, {
      paymentOption: 'autopay',
    });
  }, [switchToAutoPayOption]);

  useEffect(() => {
    if (!isSwitchingPaymentOption && !error && !paymentOption) {
      fetchCurrentPaymentOption();
    }
  }, [isSwitchingPaymentOption, error, paymentOption, fetchCurrentPaymentOption]);

  if (switchedPaymentOption === true) {
    return (
      <Redirect to="/payment-options/switch-to-autopay/success" />
    );
  }

  // This component is meant to handle switch to autopay with user having pending balance
  if (canSwitchPaymentOption) {
    return (
      <Redirect to="/payment-options" />
    );
  }

  return (
    <Container sidebar={false}>
      <Panel>
        <Form
          aria-label="Switch to Auto Pay option"
          method="PUT"
          action="/payment-options/switch-to-autopay"
          onSubmit={handleSwitchToAutoPay}
          loading={isSwitchingPaymentOption}
        >
          <PwGBCardImage tilted />
          <Header as="h2">Save More with Auto Pay</Header>
          <input type="hidden" name="paymentOption" value="autopay" />
          <FlexGrid>
            <FlexGrid.Column desktop={9}>
              <Text>
                By clicking continue, you agree to pay your existing balance of {amountOwed.startsWith('$') ? amountOwed : formatPrice(amountOwed)}.
                {' '}
                {statementNotYetGenerated
                  ? 'A statement will be generated, and any pending or future transactions will be settled automatically.'
                  : 'Any existing or future transactions will be settled automatically.'
                }
              </Text>
            </FlexGrid.Column>
          </FlexGrid>
          <br />
          <br />
          {!!error?.length && (
            <Fragment>
              <Text color="orange">{error}</Text>
              <br />
              <br />
            </Fragment>
          )}
          <Button type="submit" primary wide disabled={isSwitchingPaymentOption}>
            Continue
          </Button>
          <Button secondary wide as="a" href={`//${consumerHost}/account/savings`}>
            Cancel
          </Button>
        </Form>
      </Panel>
    </Container>
  );
}

SwitchToAutoPay.propTypes = {
  amountOwed: PropTypes.string,
  canSwitchPaymentOption: PropTypes.bool,
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  fetchCurrentPaymentOption: PropTypes.func,
  isSwitchingPaymentOption: PropTypes.bool,
  paymentOption: PaymentOptionPropType,
  statementNotYetGenerated: PropTypes.bool,
  switchedPaymentOption: PropTypes.bool,
  switchToAutoPayOption: PropTypes.func,
};

SwitchToAutoPay.defaultProps = {
  amountOwed: undefined,
  canSwitchPaymentOption: false,
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  fetchCurrentPaymentOption: () => {},
  isSwitchingPaymentOption: false,
  paymentOption: undefined,
  statementNotYetGenerated: undefined,
  switchedPaymentOption: false,
  switchToAutoPayOption: () => {},
};
