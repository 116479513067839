import { Button, FlexGrid, Form, Header, Text, Visibility } from '@gasbuddy/react-components';
import { MagicLinkButton } from '@gasbuddy/react-consumer-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import isValidPassword from '../../../lib/utils/isValidPassword';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useSimpleInput from '../../hooks/useSimpleInput';
import useTracking from '../../hooks/useTracking';
import InputWithValidation from '../InputWithValidation';
import LineThrough from '../LineThrough';
import SocialAuthButtons from '../SocialAuthButtons';
import styles from './LoginForm.module.css';

const cx = classnames.bind(styles);

export default function LoginForm({
  basePath,
  email: defaultEmail,
  error,
  identityHost,
  isLoggingIn,
  isSigningUp,
  loginWithCode,
  loginWithGBAccount,
  requestMagicLink,
  magicLinkError,
  magicLinkLoading,
  magicLinkStatus,
  magicLinkDevice,
  payHost,
  postalCode,
  returnPath,
  signupError,
}) {
  const [identifier, setIdentifier] = useSimpleInput(defaultEmail);
  const [identifierError, setIdentifierError] = useState(undefined);
  const [password, setPassword] = useSimpleInput();
  const [passwordError, setPasswordError] = useState(undefined);
  const { analytics } = useTracking(ANALYTICS_SCREENS.PAYPLUS_ENROLL_LOGIN);
  const returnUrl = `//${payHost}${basePath}${returnPath}`;

  const [canSubmit, setCanSubmit] = useState(true);
  const [canRequestMagicLink, setCanRequestMagicLink] = useState(true);
  useEffect(() => {
    setCanSubmit(identifier && password && !identifierError && !passwordError);
    setCanRequestMagicLink(!!identifier.length && !identifierError && !magicLinkLoading);
  }, [identifier, password, identifierError, passwordError, magicLinkLoading]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const validIdentifier = identifier.trim() !== '';
    const validPassword = isValidPassword(password);

    if (!validIdentifier) {
      setIdentifierError('A valid email address or username is required.');
    }

    if (!validPassword) {
      setPasswordError('A valid password of at least 4 characters is required.');
    }

    if (!validIdentifier || !validPassword) {
      return;
    }

    loginWithGBAccount({
      identifier,
      return_url: returnUrl,
      password,
    }, identityHost, analytics);
  }, [analytics, identifier, identityHost, loginWithGBAccount, password, returnUrl]);

  const onMagicLinkClick = (e) => {
    e.preventDefault();

    const validIdentifier = identifier.trim() !== '';

    if (!validIdentifier) {
      setIdentifierError('A valid email address or username is required.');
      return;
    }

    requestMagicLink({
      identifier,
      flowType: 'pay_enroll',
      generate: undefined,
      returnUrl,
    }, identityHost);
  };

  const handleCodeSubmit = (e, code) => {
    e.stopPropagation(); // Stop form submission
    loginWithCode({ code }, identityHost);
  };

  const authError = error || signupError;

  return (
    <React.Fragment>
      <Header as="h2">Welcome back</Header>
      <Text as="p">{defaultEmail ? 'We recognize this email address. ' : ''}Please sign in to your GasBuddy account before continuing.</Text>
      <br />
      <br />
      {!!authError && (
        <Text as="p" color="orange">{authError}</Text>
      )}
      <FlexGrid container>
        <FlexGrid.Column tablet={6}>
          <Form
            aria-label="Login Form"
            action={`//${identityHost}/login?return_url=${returnUrl}`}
            loading={isLoggingIn || isSigningUp}
            method="post"
            onSubmit={handleSubmit}
          >
            <InputWithValidation
              autoFocus={!defaultEmail}
              id="email"
              name="identifier"
              data-testid="emailInput"
              label="Email"
              error={identifierError}
              value={identifier}
              onChange={setIdentifier}
            />
            {identifierError && (
              <br />
            )}
            <InputWithValidation
              autoFocus={!!defaultEmail}
              aria-label="password"
              id="password"
              name="password"
              data-testid="passwordInput"
              type="password"
              label="Password"
              error={passwordError}
              className={cx('input')}
              value={password}
              onChange={setPassword}
            />
            {passwordError && (
              <br />
            )}
            <Button primary cta type="submit" fluid disabled={!canSubmit}>
              Continue
            </Button>
          </Form>

          <Visibility mobile={false} tablet={false}>
            <LineThrough uppercase>or</LineThrough>
            <br />
          </Visibility>
          {!!magicLinkError && (
            <Text centered color="orange">{magicLinkError}</Text>
          )}

          <Form
            aria-label="Login Link Form"
            action={`//${identityHost}/login-link`}
            className={cx('magicLinkForm')}
            onSubmit={onMagicLinkClick}
            method="POST"
          >
            <input type="hidden" value={identifier} />
            <MagicLinkButton
              deviceLabel={magicLinkDevice}
              disabled={!canRequestMagicLink}
              errorMessage={error}
              isLoggingIn={isLoggingIn}
              loading={magicLinkLoading}
              onCodeSubmit={handleCodeSubmit}
              useCode={false}
              variant={magicLinkStatus}
            />
            <Text centered size="sm">
              We&apos;ll send you an email so you can log in with a simple click.
            </Text>
          </Form>

        </FlexGrid.Column>
        <FlexGrid.Column className={cx('socialColumn')} tabletOffset={1} tablet={5}>
          <SocialAuthButtons
            identityHost={identityHost}
            magicLinkReturnUrl={returnUrl}
            postalCode={postalCode}
            accountsConnectedButtonUrl="/enroll"
            returnUrl={returnUrl}
          />
        </FlexGrid.Column>
      </FlexGrid>
      <br />
      <br />
    </React.Fragment>
  );
}

LoginForm.propTypes = {
  basePath: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.string,
  identityHost: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  isSigningUp: PropTypes.bool,
  loginWithCode: PropTypes.func,
  loginWithGBAccount: PropTypes.func,
  magicLinkError: PropTypes.string,
  requestMagicLink: PropTypes.func,
  magicLinkLoading: PropTypes.bool,
  payHost: PropTypes.string,
  magicLinkStatus: PropTypes.number,
  magicLinkDevice: PropTypes.string,
  postalCode: PropTypes.string,
  returnPath: PropTypes.string,
  signupError: PropTypes.string,
};

LoginForm.defaultProps = {
  basePath: '',
  email: undefined,
  error: undefined,
  identityHost: 'iam.gasbuddy.com',
  isLoggingIn: false,
  magicLinkLoading: false,
  magicLinkStatus: undefined,
  magicLinkDevice: undefined,
  isSigningUp: false,
  loginWithCode: () => {},
  loginWithGBAccount: () => {},
  requestMagicLink: () => {},
  magicLinkError: undefined,
  payHost: 'pay.gasbuddy.com',
  postalCode: undefined,
  returnPath: '',
  signupError: undefined,
};
