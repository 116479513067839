const LINKS = {
  GB_TERMS: 'https://help.gasbuddy.com/hc/en-us/articles/115005755528-Terms-of-Service',
  GB_PRIVACY_POLICY: 'https://help.gasbuddy.com/hc/en-us/articles/115005591687-Privacy-Policy/',
  GB_CHARGE_CARD_AGREEMENT: 'https://help.gasbuddy.com/hc/en-us/articles/23647236074519-Pay-with-GasBuddy-Cardholder-Agreement',
  DWOLLA_TERMS: 'https://www.dwolla.com/legal/tos/',
  DWOLLA_PRIVACY_POLICY: 'https://www.dwolla.com/legal/privacy/',
  GB_ESGIN_CONSENT: 'https://help.gasbuddy.com/hc/en-us/articles/25320080217495-E-Consent-Terms',
};

export default LINKS;
