import { Button, Constraint, FlexGrid, Link, Loader, Text, Visibility } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import LineThrough from '../LineThrough';
import PlaidButton from '../PlaidButton';
import styles from './CheckingAccountWelcome.module.css';
import IavButton from '../IavButton';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_EVENTS } from '../../constants/analytics';

const cx = classnames.bind(styles);

export default function CheckingAccountWelcome({
  iavProvider,
  switchToManual,
  iavEnv,
  iavSessionToken,
  isCreatingInstrument,
  isFetchingIavProviderDetails,
  errorFetchingIavProviderDetails,
  fetchIavProviderDetails,
}) {
  const unsupportedProvider = !['plaid', 'validifi'].includes(iavProvider);
  const usingValidiFi = iavProvider === 'validifi';
  const { analytics } = useTracking();
  const missingIavDetails = useMemo(() => !iavEnv || !iavSessionToken, [iavEnv, iavSessionToken]);

  useEffect(() => {
    if (missingIavDetails && !isFetchingIavProviderDetails && !errorFetchingIavProviderDetails) {
      fetchIavProviderDetails();
    }
  }, [
    missingIavDetails,
    fetchIavProviderDetails,
    isFetchingIavProviderDetails,
    errorFetchingIavProviderDetails,
  ]);

  const handleSwitchToManualBankLinking = useCallback((e) => {
    analytics.tagEvent(ANALYTICS_EVENTS.PAYPLUS_ENROLL_BANK_START_MANUAL);
    switchToManual(e);
  }, [switchToManual, analytics]);

  if (missingIavDetails && isFetchingIavProviderDetails && !errorFetchingIavProviderDetails) {
    return (
      <Loader data-testid="loader" size="lg" />
    );
  }

  let link = <React.Fragment />;
  if (usingValidiFi) {
    link = (
      <IavButton
        className={cx('button')}
        fluid
        switchToManual={switchToManual}
        loading={isCreatingInstrument}
        primary
      >
        Link Account Instantly
      </IavButton>
    );
  } else if (iavProvider === 'plaid') {
    link = (
      <PlaidButton
        className={cx('button')}
        fluid
        onReject={switchToManual}
        loading={isCreatingInstrument}
        primary
      >
        Link Account Instantly
      </PlaidButton>
    );
  }

  // Note: we do not need to trigger a page view event here because it was done in CheckingAccountForm
  return (
    <React.Fragment>
      <Text as="p" className={cx('accountInfoCopy')}>
        GasBuddy protects your bank account information using best practices in the payment industry to ensure your data is kept private and secure.
        All banking and transaction data is transmitted securely to and from our service partners.
        <br />
        <br />
        You&apos;ll need to link your checking account to your new Pay with GasBuddy+™ card
        through either of these options:
      </Text>
      <FlexGrid container className={cx('checkingWelcomeButtonGrid')}>
        <FlexGrid.Column className={cx('linkOption')} tablet={6}>
          <Text bold>Instant account verification</Text>
          <Text as="p">
            Instantly link your bank account by using our&nbsp;
            <Link
              bold
              to={iavProvider === 'plaid'
                ? 'https://help.gasbuddy.com/hc/en-us/articles/360021574753-Plaid'
                : 'https://help.gasbuddy.com/hc/en-us/articles/23812296991511-Who-is-ValidiFI'
              }
              target="_blank"
            >
              instant verification service
            </Link>
            &nbsp;to log in to your checking account online.
          </Text>
          <Text
            as="p"
            bold
            className={cx('darkGreyMessageFocus')}
          >
            Highly recommended and instantly verified.
          </Text>
          <Constraint desktop={9}>
            {(unsupportedProvider || errorFetchingIavProviderDetails) ? (
              <Text as="p" color="orange">
                Instant account verification is unavailable, please link account manually.
              </Text>
            ) : link}
          </Constraint>
          <Visibility mobile desktop={false} tablet={false}>
            <LineThrough uppercase>or</LineThrough>
          </Visibility>
        </FlexGrid.Column>
        <FlexGrid.Column className={cx('linkOption')} tablet={6}>
          <Text bold>Link your account manually</Text>
          <Text as="p">
            Enter your checking account number and routing number. You’ll need to confirm&nbsp;
            <Link
              bold
              to="https://help.gasbuddy.com/hc/en-us/articles/360034169934-Linking-your-bank-account"
              target="_blank"
            >
              small deposits
            </Link>
            &nbsp;in the account you linked.
          </Text>
          <Text
            as="p"
            bold
            className={cx('darkGreyMessageFocus')}
          >
            This process takes 1-6 business days.
          </Text>
          <Constraint desktop={9}>
            <Button fluid secondary onClick={handleSwitchToManualBankLinking} className={cx('button')}>
              Link Account Manually
            </Button>
          </Constraint>
        </FlexGrid.Column>
      </FlexGrid>
    </React.Fragment>
  );
}

CheckingAccountWelcome.propTypes = {
  iavProvider: PropTypes.string,
  isCreatingInstrument: PropTypes.bool,
  errorFetchingIavProviderDetails: PropTypes.string,
  fetchIavProviderDetails: PropTypes.func,
  iavEnv: PropTypes.string,
  iavSessionToken: PropTypes.string,
  isFetchingIavProviderDetails: PropTypes.bool,
  switchToManual: PropTypes.func,
};

CheckingAccountWelcome.defaultProps = {
  iavProvider: 'validifi',
  isCreatingInstrument: false,
  errorFetchingIavProviderDetails: undefined,
  fetchIavProviderDetails: () => {},
  iavEnv: 'production',
  iavSessionToken: undefined,
  isFetchingIavProviderDetails: false,
  switchToManual: () => { },
};
