import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Form, FlexGrid, Text, Input, Constraint } from '@gasbuddy/react-components';
import CardActivationHeader from '../CardActivationHeader';
import WalletPropType from '../../prop-types/wallet';
import InputWithValidation from '../InputWithValidation/InputWithValidation';
import styles from './MicrodepositForm.module.css';
import FormActionButtons from '../FormActionButtons';
import AccountStatus from '../../constants/accountStatus';
import getMDReadyAccount from '../../../lib/utils/getMDReadyAccount';

const cx = classnames.bind(styles);
const VALID_AMOUNT = /^0?.\d\d$/;

export default function MicrodepositForm({ isSaving, error, onSubmit, wallet }) {
  const bankAccount = getMDReadyAccount(wallet.instruments) || {};
  const instrumentId = bankAccount.instrument_id;
  const defaultFormProperties = {
    amount1: '',
    amount2: '',
  };

  const [formValues, setFormValues] = React.useState(defaultFormProperties);
  const [formErrors, setFormErrors] = React.useState(defaultFormProperties);
  const [formValidation, setFormValidation] = React.useState({
    amount1: undefined,
    amount2: undefined,
  });

  const handleFieldChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setFormValidation({
      ...formValidation,
      [name]: undefined,
    });
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }, [formErrors, formValues, formValidation]);

  const handleAmountBlur = React.useCallback((e) => {
    const { name, value } = e.target;
    const newFormValidation = { ...formValidation };
    const newFormErrors = { ...formErrors };

    if (value) {
      if (!VALID_AMOUNT.test(value)) {
        newFormErrors[name] = 'Invalid deposit amount';
        newFormValidation[name] = false;
      } else {
        newFormValidation[name] = true;
      }
    } else {
      newFormValidation[name] = undefined;
    }

    setFormErrors(newFormErrors);
    setFormValidation(newFormValidation);
  }, [formErrors, formValidation]);

  const handleSubmit = React.useCallback((e) => {
    let isValid = true;
    const newFormErrors = { ...defaultFormProperties };

    if (!VALID_AMOUNT.test(formValues.amount1)) {
      newFormErrors.amount1 = 'Invalid deposit amount';
      isValid = false;
    }

    if (!VALID_AMOUNT.test(formValues.amount2)) {
      newFormErrors.amount2 = 'Invalid deposit amount';
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      e.preventDefault();
    } else {
      setFormErrors(defaultFormProperties);
      onSubmit(e, {
        ...formValues,
        instrumentId,
      });
    }
  }, [defaultFormProperties, formValues, onSubmit, instrumentId]);

  const hasOutstandingBalance = wallet.account_status === AccountStatus.WaitingForMicrodepositConfirmationWithOutstandingBalance;
  const message = hasOutstandingBalance
    ? <Text>Please verify your bank account by confirming the two small deposits in your bank account. Failure to confirm your new bank account will result in your balance being sent to a collection agency. If you haven&apos;t received the deposits, please contact <a href="https://help.gasbuddy.com/hc/en-us/requests/new?ticket_form_id=453327">Customer Support</a>.</Text>
    : 'Enter the microdeposit amounts as seen in your checking account below.';

  return (
    <Form
      aria-label="Microdeposit confirmation form"
      action="/microdeposits"
      method="post"
      onSubmit={handleSubmit}
      loading={isSaving}
    >
      <Input type="hidden" name="instrumentId" value={instrumentId} />
      <CardActivationHeader title="Confirm microdeposits" />
      <br />
      <Text as="p">{message}</Text>
      <br />
      {!!error && (
        <Text as="p" color="orange">
          {error}
        </Text>
      )}
      <FlexGrid container>
        <FlexGrid.Column className={cx('field')} tablet={6} desktop={5}>
          <InputWithValidation
            name="amount1"
            label="Microdeposit amount ($0.00)"
            onBlur={handleAmountBlur}
            onChange={handleFieldChange}
            error={formErrors.amount1}
            isValid={formValidation.amount1}
            required
          />
        </FlexGrid.Column>
        <FlexGrid.Column className={cx('field')} tablet={6} desktop={5}>
          <InputWithValidation
            name="amount2"
            label="Microdeposit amount ($0.00)"
            onBlur={handleAmountBlur}
            onChange={handleFieldChange}
            error={formErrors.amount2}
            isValid={formValidation.amount2}
            required
          />
        </FlexGrid.Column>
      </FlexGrid>
      <br />
      <Constraint desktop={10}>
        <FormActionButtons canSubmit={!isSaving} />
      </Constraint>
    </Form>
  );
}

MicrodepositForm.propTypes = {
  wallet: WalletPropType,
  isSaving: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

MicrodepositForm.defaultProps = {
  wallet: {},
  isSaving: false,
  error: undefined,
  onSubmit: () => {},
};
