import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goToStep } from '../../reducers/actions';
import MigrateCardFlow from './MigrateCardFlow';

function mapStateToProps({ config, progress, wallet }) {
  return {
    payHost: config.payHost,
    wallet,
    hasPaymentOptionSet: progress.esigned || wallet.status?.esign,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToStep(index) {
      dispatch(goToStep(index));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MigrateCardFlow));
