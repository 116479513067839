import React, { useEffect } from 'react';
import { Header, Loader } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import Container from '../../components/Container';
import Panel from '../../components/Panel';
import PaymentOptions from '../../components/PaymentOptions';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import isMembershipProgram from '../../../lib/utils/isMembershipProgram';

export default function UpdatePaymentOptionsFlow({
  paymentOption,
  payProgram,
  isLoading,
  error,
  fetchCurrentPaymentOption,
}) {
  useEffect(() => {
    if (!isLoading && !error && !paymentOption) {
      fetchCurrentPaymentOption();
    }
  }, [isLoading, error, paymentOption, fetchCurrentPaymentOption]);

  let content = (
    <React.Fragment>
      <Header as="h2">ACH Payment Options</Header>
      <PaymentOptions
        skipReview
        paymentOption={paymentOption}
        isMembershipUser={isMembershipProgram(payProgram)}
      />
    </React.Fragment>
  );

  if (isLoading) {
    content = (
      <Loader data-testid="loader" size="lg" />
    );
  }

  return (
    <Container sidebar={false}>
      <Panel>
        {content}
      </Panel>
    </Container>
  );
}

UpdatePaymentOptionsFlow.propTypes = {
  paymentOption: PaymentOptionPropType,
  payProgram: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  fetchCurrentPaymentOption: PropTypes.func,
};

UpdatePaymentOptionsFlow.defaultProps = {
  paymentOption: undefined,
  payProgram: undefined,
  isLoading: false,
  error: undefined,
  fetchCurrentPaymentOption: () => {},
};
