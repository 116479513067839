import { Constraint, FlexGrid, Form, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { VALID_INTEGER_REGEX } from '../../../lib/utils/isValidInteger';
import InstrumentPropType from '../../prop-types/instrument';
import CardActivationHeader from '../CardActivationHeader';
import FormActionButtons from '../FormActionButtons';
import InputWithValidation from '../InputWithValidation';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function MigratingCardActivationForm({
  card,
  isSaving,
  error,
  onSubmit,
}) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_MIGRATE_ACTIVATION_CONFIRM_DIGITS);

  const defaultFormProperties = {
    last4: '',
  };
  const [formValues, setFormValues] = useState(defaultFormProperties);
  const [formErrors, setFormErrors] = useState(defaultFormProperties);

  const handleFieldChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }, [formErrors, formValues]);

  const handleSubmit = useCallback((e) => {
    let isValid = true;
    const newFormErrors = { ...defaultFormProperties };

    if (formValues.last4.length !== 4) {
      newFormErrors.last4 = 'Please enter 4 digits';
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      e.preventDefault();
    } else {
      setFormErrors(defaultFormProperties);
      onSubmit(e, {
        ...formValues,
        instrumentId: card.instrument_id,
      });
    }
  }, [defaultFormProperties, onSubmit, formValues, card.instrument_id]);

  return (
    <Form aria-label="Card Activation Form" action="/migrate" method="post" loading={isSaving} onSubmit={handleSubmit}>
      <Constraint desktop={10}>
        <InputWithValidation type="hidden" name="instrumentId" value={card.instrument_id} />
        <CardActivationHeader title="Activate your card" />
        <br />
        <Text as="p">Before you can start using your card, please enter the last 4 digits on your card below.</Text>
        <br />
        {!!error && (
          <Text as="p" color="orange">
            {error}
          </Text>
        )}
        <FlexGrid container>
          <FlexGrid.Column tablet={6}>
            <InputWithValidation
              mask={VALID_INTEGER_REGEX}
              name="last4"
              label="Last 4 digits on card"
              onChange={handleFieldChange}
              isValid={formErrors.last4 ? false : undefined}
              error={formErrors.last4}
              required
            />
          </FlexGrid.Column>
        </FlexGrid>
        <br />
        <FormActionButtons canSubmit={!isSaving} primaryButtonText="Activate Now" />
      </Constraint>
    </Form>
  );
}

MigratingCardActivationForm.propTypes = {
  card: InstrumentPropType.isRequired,
  error: PropTypes.string,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
};

MigratingCardActivationForm.defaultProps = {
  error: undefined,
  isSaving: false,
  onSubmit: () => {},
};
