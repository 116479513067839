import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Text } from '@gasbuddy/react-components';
import PwGBCardImage from '../PwGBCardImage';
import Container from '../Container';
import Panel from '../Panel';

export default function SwitchToAutoPaySuccess({ consumerHost }) {
  return (
    <Container sidebar={false}>
      <Panel>
        <PwGBCardImage tilted />
        <Header as="h2">Thanks for your payment!</Header>
        <Text>
          Your payment has been submitted for processing.
          Once complete, Auto Pay will be enabled.
        </Text>
        <br />
        <br />
        <Button primary wide as="a" href={`//${consumerHost}/account/savings`}>
          Continue
        </Button>
      </Panel>
    </Container>
  );
}

SwitchToAutoPaySuccess.propTypes = {
  consumerHost: PropTypes.string,
};

SwitchToAutoPaySuccess.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
