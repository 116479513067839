import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './CardActivationHeader.module.css';
import PwGBCardImage from '../PwGBCardImage';

const cx = classnames.bind(styles);

export default function CardActivationHeader({
  title,
  subtitle,
}) {
  return (
    <Fragment>
      <PwGBCardImage />
      <Header as="h2">{title}</Header>
      {!!subtitle
        && (
          <Header as="h3" uppercase elongated className={cx('subtitle')}>{subtitle}</Header>
        )
      }
    </Fragment>
  );
}

CardActivationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

CardActivationHeader.defaultProps = {
  subtitle: undefined,
};
