import React from 'react';
import { Text } from '@gasbuddy/react-components';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';

export default function PaymentOptionsSummary({ paymentOption }) {
  return (
    <Text as="p">
      {paymentOption === 'autopay' ? 'Auto Pay' : 'Pay my statement balance manually'}
    </Text>
  );
}

PaymentOptionsSummary.propTypes = {
  paymentOption: PaymentOptionPropType,
};

PaymentOptionsSummary.defaultProps = {
  paymentOption: undefined,
};
