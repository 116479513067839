import React from 'react';
import { Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import styles from './PaymentOptions.module.css';

const cx = classnames.bind(styles);

const MembershipBenefits = () => (
  <Text className={cx('benefits')} as="p">
    <strong>20¢/gal savings</strong> on every fill-up up to 50 gal/month, and 5¢/gal thereafter<br />
    <strong>22¢/gal savings</strong> at select stations up to 50 gal/month, and 7¢/gal thereafter.<br />
    Spend $25 in the c-store, earn an <strong>extra 3¢/gal</strong><br />
    <strong>$100 daily</strong> spending limit (per 24 hours)<br />
    <strong>$400 weekly</strong> spending limit (per 7 days)<br />
    Automatically pay your card balance
  </Text>
);

const RegularBenefits = () => (
  <Text className={cx('benefits')} as="p">
    <strong>3¢/gal savings</strong> on every fill-up<br />
    <strong>5¢/gal savings</strong> at select stations<br />
    Spend $25 in the c-store, earn <strong>an extra 3¢/gal</strong><br />
    <strong>$100 daily</strong> spending limit (per 24 hours)<br />
    <strong>$400 weekly</strong> spending limit (per 7 days)<br />
    Automatically pay your card balance
  </Text>
);

export default function AutopayPaymentOption({ isMembershipUser, interactive }) {
  return (
    <React.Fragment>
      <Text className={cx('label')}>Auto Pay</Text>
      {isMembershipUser ? <MembershipBenefits /> : <RegularBenefits />}
      {interactive && (
        <Text as="span" className={cx('pill')}>
          <FontAwesomeIcon
            icon={faCheck}
            size="1x"
          />
          &nbsp;
          Recommended
        </Text>
      )}
    </React.Fragment>
  );
}

AutopayPaymentOption.propTypes = {
  isMembershipUser: PropTypes.bool,
  interactive: PropTypes.bool,
};

AutopayPaymentOption.defaultProps = {
  isMembershipUser: false,
  interactive: false,
};
