export const VALID_ROUTING_NUMBER_LENGTH = 9;

export const MINIMUM_ACCOUNT_NUMBER_LENGTH = 3;

export const IdentityVerificationStatus = {
  Failed: 'IdentityVerificationFailed',
  TryAgain: 'RetryIdentityVerification',
};

export const MAX_IDENTITY_VERIFICATION_ATTEMPTS = 3;
