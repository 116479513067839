import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Actions,
  Button,
  FlexGrid,
  Form,
  Header,
  Link,
  Modal,
  Radio,
  Text,
} from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { usePrevious } from '@gasbuddy/react-hooks';
import classnames from 'classnames/bind';
import FormActionButtons from '../FormActionButtons';
import styles from './PaymentOptions.module.css';
import AutopayPaymentOption from './AutopayPaymentOption';
import ManualPaymentOption from './ManualPaymentOption';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import TermsOfService from '../TermsOfService';
import EsignTermsCheckbox from '../EsignTermsCheckbox';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';

const cx = classnames.bind(styles);

function ConfirmManualPaymentOption({ onContinue, onCancel }) {
  return (
    <React.Fragment>
      <Header as="h3">Are you sure?</Header>
      <Text as="p">
        By selecting to pay your balance manually, you will be missing out on savings.
      </Text>
      <br />
      <br />
      <Actions>
        <Actions.Button primary wide onClick={onCancel}>Back</Actions.Button>
        <Actions.Button secondary type="submit" wide onClick={onContinue}>Continue</Actions.Button>
      </Actions>
    </React.Fragment>
  );
}

ConfirmManualPaymentOption.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// TODO: Revise Contact Support link to load Pay with GasBuddy specific form
function ManualPayUnavailableForMembershipUsers({ onCancel }) {
  return (
    <React.Fragment>
      <Header as="h3">Contact Support</Header>
      <Text as="p">
        Manual bill pay is not available to Premium or Plus members at this time.
        Please contact support for help.
      </Text>
      <br />
      <br />
      <Actions>
        <Actions.Button primary wide onClick={onCancel}>Back</Actions.Button>
        <Actions.Button
          secondary
          as="a"
          href="https://help.gasbuddy.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener noreferrer"
          fluid
        >
          Contact Support
        </Actions.Button>
      </Actions>
    </React.Fragment>
  );
}

ManualPayUnavailableForMembershipUsers.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default function PaymentOptions({
  canSwitchPaymentOption,
  consumerHost,
  enforceTermsAgreement,
  error,
  isMembershipUser,
  isSavingPaymentOption,
  paymentOption,
  requireEsign,
  skipReview: updatingPaymentOption,
  updateCurrentPaymentOption,
  updatedPaymentOption,
  updatePaymentOptionForPendingEnrollment,
}) {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(paymentOption || 'autopay');
  const [isShowingAllPaymentOptions, setIsShowingAllPaymentOptions] = useState(paymentOption === 'manual');
  const wasSaving = usePrevious(isSavingPaymentOption);
  const [showReviewAndSubmit, setShowReviewAndSubmit] = useState(false);
  const [encourageAutopay, setEncourageAutopay] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [esigned, setEsigned] = useState(false);
  const [shouldOfferAutoPaySwitch, setShouldOfferAutoPaySwitch] = useState(false);
  const isMigrating = requireEsign && enforceTermsAgreement;

  let analyticsScreenName = ANALYTICS_SCREENS.PAYPLUS_ENROLL_ACH_PAYMENT;
  if (isMigrating) {
    analyticsScreenName = ANALYTICS_SCREENS.PAYPLUS_MIGRATE_ACTIVATION_ACHPAYMENT;
  } else if (updatingPaymentOption) {
    analyticsScreenName = ANALYTICS_SCREENS.PAYPLUS_UPDATE_ACH_PAYMENT;
  }

  const { trackEvent } = useTracking(analyticsScreenName);

  const handleViewOtherOptions = useCallback(() => {
    setIsShowingAllPaymentOptions(true);
  }, []);

  const handleChange = useCallback(({ target }) => {
    setSelectedPaymentOption(target.value);
  }, []);

  const handleCancelManualPaymentSelection = useCallback(() => {
    setEncourageAutopay(false);
  }, []);

  const proceedWithSelectedPaymentOption = useCallback((e) => {
    const body = {
      paymentOption: selectedPaymentOption,
    };

    let analyticsEvent = ANALYTICS_EVENTS.PAYPLUS_ENROLL_ACH_PAYMENT_SELECTED;
    if (isMigrating) {
      analyticsEvent = ANALYTICS_EVENTS.PAYPLUS_MIGRATE_ACTIVATION_ACHPAYMENT_SELECTED;
    } else if (updatingPaymentOption) {
      analyticsEvent = ANALYTICS_EVENTS.PAYPLUS_UPDATE_ACH_PAYMENT_SELECTED;
    }
    trackEvent(analyticsEvent, {
      paymentOption: selectedPaymentOption,
    });

    if (updatingPaymentOption) {
      if (!requireEsign
        && !enforceTermsAgreement
        && !canSwitchPaymentOption
        && selectedPaymentOption === 'autopay'
      ) {
        // User wants to switch to autopay, but auto-switch is not allowed
        setShouldOfferAutoPaySwitch(true);
      } else {
        updateCurrentPaymentOption(e, {
          ...body,
          esign: requireEsign ? esigned : undefined,
          acceptedTerms: enforceTermsAgreement ? acceptedTerms : undefined,
        });
      }
    } else {
      updatePaymentOptionForPendingEnrollment(e, body);
    }
  }, [
    canSwitchPaymentOption,
    selectedPaymentOption,
    updatingPaymentOption,
    updatePaymentOptionForPendingEnrollment,
    updateCurrentPaymentOption,
    requireEsign,
    enforceTermsAgreement,
    acceptedTerms,
    esigned,
    trackEvent,
    isMigrating,
  ]);

  const handleSubmit = useCallback((e) => {
    if (selectedPaymentOption === 'manual') {
      e.preventDefault();
      setEncourageAutopay(true);
    } else {
      proceedWithSelectedPaymentOption(e);
    }
  }, [selectedPaymentOption, proceedWithSelectedPaymentOption]);

  const handleEsignCheckboxToggle = useCallback(({ target }) => {
    setEsigned(target.checked);
  }, []);

  useEffect(() => {
    if (wasSaving && !isSavingPaymentOption && !updatingPaymentOption && !error && paymentOption) {
      setShowReviewAndSubmit(true);
    }
  }, [wasSaving, isSavingPaymentOption, error, paymentOption, selectedPaymentOption, updatingPaymentOption]);

  const canSubmit = useMemo(() => {
    const hasPaymentOptionSelected = (['autopay', 'manual'].includes(selectedPaymentOption));
    const hasAgreedToTerms = enforceTermsAgreement ? acceptedTerms : true;
    const hasEsigned = requireEsign ? esigned : true;
    return hasPaymentOptionSelected && hasAgreedToTerms && hasEsigned;
  }, [selectedPaymentOption, enforceTermsAgreement, acceptedTerms, requireEsign, esigned]);

  if (showReviewAndSubmit) {
    return (
      <Redirect to="/enroll/review" />
    );
  }

  if (shouldOfferAutoPaySwitch) {
    return (
      <Redirect to="/payment-options/switch-to-autopay" />
    );
  }

  if (updatedPaymentOption === true) {
    return (
      <Redirect to="/payment-options/success" />
    );
  }

  return (
    <Form
      aria-label="Payment Options Form"
      action={updatingPaymentOption ? '/payment-options' : '/billing/payment-options'}
      method={updatingPaymentOption ? 'PUT' : 'POST'}
      loading={isSavingPaymentOption}
      onSubmit={handleSubmit}
    >
      <Text as="p">
        Select your preferred ACH payment method for settling your bill.
        Auto pay is recommended to maximize your savings and avoid worrying about missed payments.
      </Text>
      {requireEsign && (
        <Text bold color="midnight">
          Have questions?
          Check out our <Link bold href={`//${consumerHost}/pay#faq`} target="_blank" rel="noopener noreferrer">FAQs</Link>.
        </Text>
      )}
      <br />
      <br />
      <Modal
        size="md"
        content={isMembershipUser ? () => (
          <ManualPayUnavailableForMembershipUsers
            onCancel={handleCancelManualPaymentSelection}
          />
        ) : () => (
          <ConfirmManualPaymentOption
            onCancel={handleCancelManualPaymentSelection}
            onContinue={proceedWithSelectedPaymentOption}
          />
        )}
        onClose={handleCancelManualPaymentSelection}
        forceIsShowing={encourageAutopay}
      />
      <FlexGrid container>
        <FlexGrid.Column tablet={6} mobile={12}>
          <div className={cx('paymentOption', { recommended: isShowingAllPaymentOptions })}>
            <Radio
              checked={selectedPaymentOption === 'autopay'}
              className={cx('radio')}
              name="paymentMethod"
              value="autopay"
              onChange={handleChange}
            >
              <div className={cx('optionDetails')}>
                <AutopayPaymentOption interactive isMembershipUser={isMembershipUser} />
              </div>
            </Radio>
          </div>
        </FlexGrid.Column>
        {isShowingAllPaymentOptions && (
          <FlexGrid.Column tablet={6} mobile={12}>
            <div className={cx('paymentOption')}>
              <Radio
                checked={selectedPaymentOption !== 'autopay'}
                className={cx('radio')}
                name="paymentMethod"
                value="manual"
                onChange={handleChange}
              >
                <div className={cx('optionDetails')}>
                  <ManualPaymentOption isMembershipUser={isMembershipUser} />
                </div>
              </Radio>
            </div>
          </FlexGrid.Column>
        )}
      </FlexGrid>
      {requireEsign && (
        <EsignTermsCheckbox onTermsChecked={handleEsignCheckboxToggle} />
      )}
      {enforceTermsAgreement && (
        <FlexGrid container>
          <FlexGrid.Column desktop={10}>
            <TermsOfService buttonText="Continue" onCheck={setAcceptedTerms} />
          </FlexGrid.Column>
        </FlexGrid>
      )}
      {!!error && (
        <Text as="p" color="orange">
          {error}
        </Text>
      )}
      <FormActionButtons
        canSubmit={!isSavingPaymentOption && canSubmit}
        primaryButtonText="Continue"
      />
      <br />
      {!isShowingAllPaymentOptions && (
        <FlexGrid container>
          <FlexGrid.Column tablet={6} desktop={5} className={cx('actions')}>
            <Button link className={cx('showAllOptionsLink')} onClick={handleViewOtherOptions}>
              View other options
            </Button>
          </FlexGrid.Column>
        </FlexGrid>
      )}
    </Form>
  );
}

PaymentOptions.propTypes = {
  canSwitchPaymentOption: PropTypes.bool,
  consumerHost: PropTypes.string,
  enforceTermsAgreement: PropTypes.bool,
  error: PropTypes.string,
  isMembershipUser: PropTypes.bool,
  isSavingPaymentOption: PropTypes.bool,
  paymentOption: PaymentOptionPropType,
  requireEsign: PropTypes.bool,
  skipReview: PropTypes.bool,
  updatedPaymentOption: PropTypes.bool,
  updateCurrentPaymentOption: PropTypes.func,
  updatePaymentOptionForPendingEnrollment: PropTypes.func,
};

PaymentOptions.defaultProps = {
  canSwitchPaymentOption: true,
  consumerHost: 'www.gasbuddy.com',
  enforceTermsAgreement: false,
  error: undefined,
  isMembershipUser: false,
  isSavingPaymentOption: false,
  paymentOption: undefined,
  requireEsign: false,
  skipReview: false,
  updatedPaymentOption: undefined,
  updateCurrentPaymentOption: () => {},
  updatePaymentOptionForPendingEnrollment: () => {},
};
