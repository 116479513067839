import React, { useEffect } from 'react';
import { Loader } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import PaymentOptions from '../PaymentOptions';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import isMembershipProgram from '../../../lib/utils/isMembershipProgram';

export default function MigratingCardPaymentOptions({
  paymentOption,
  program,
  isLoading,
  error,
  fetchCurrentPaymentOption,
}) {
  useEffect(() => {
    if (!isLoading && !error && !paymentOption) {
      fetchCurrentPaymentOption();
    }
  }, [isLoading, error, paymentOption, fetchCurrentPaymentOption]);

  if (isLoading) {
    return (
      <Loader data-testid="loader" size="lg" />
    );
  }

  return (
    <PaymentOptions
      skipReview
      paymentOption={paymentOption}
      isMembershipUser={isMembershipProgram(program)}
      enforceTermsAgreement
      requireEsign
    />
  );
}

MigratingCardPaymentOptions.propTypes = {
  paymentOption: PaymentOptionPropType,
  program: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  fetchCurrentPaymentOption: PropTypes.func,
};

MigratingCardPaymentOptions.defaultProps = {
  paymentOption: undefined,
  program: undefined,
  isLoading: false,
  error: undefined,
  fetchCurrentPaymentOption: () => {},
};
