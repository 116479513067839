import { connect } from 'react-redux';
import UuidEncoder from 'uuid-encoder';
import { goToStep } from '../../reducers/actions';
import Enrollment from './Enrollment';

function mapStateToProps({ auth, config, progress, membership, wallet, instruments }) {
  const { offeringId, stepId } = progress;
  const { declinedMembership, upgraded } = membership;

  // Create Base 36 encoder
  const encoder = new UuidEncoder('base36');

  return {
    declinedMembership,
    email: auth.email || progress.email,
    isMobile: config.isMobile,
    isProduction: config.env === 'production',
    loyaltyId: auth?.guid && encoder.encode(auth.guid),
    offeringId,
    payHost: config.payHost,
    stepId,
    upgraded,
    wallet,
    hasPeriodicPaymentsSet: ['autopay', 'manual'].includes(progress.paymentOption),
    hasSubmittedIdvCheckBefore: instruments.hasSubmittedIdvCheckBefore,
    identityVerified: wallet.status?.idv,
    failedIdentityVerification: instruments.failedIdentityVerification,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToStep(index) {
      dispatch(goToStep(index));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
