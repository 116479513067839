import React from 'react';
import PropTypes from 'prop-types';
import { Button, Constraint, FlexGrid, Header, Text } from '@gasbuddy/react-components';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import PwGBCardImage from '../../components/PwGBCardImage';
import Panel from '../../components/Panel';
import Container from '../../components/Container';

export default function SwitchSuccessful({ consumerHost }) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_MIGRATE_SUCCESS);

  const nextStepsPoints = [
    'Your new card will ship in 3-5 business days.',
    'It should arrive in about 7-10 business days.',
    'Use your old card until your new card arrives.',
    'Once you receive the new card, open the app to complete enrollment and activate it using the last 4 digits.',
    'Start Saving!',
  ];

  return (
    <Container sidebar={false}>
      <Panel>
        <Constraint desktop={10}>
          <PwGBCardImage />
          <Header as="h2">Success!</Header>
          <Text as="p" elongated size="lg" uppercase>Your new card is on the way!</Text>
          <Text as="p" bold>Here’s what happens next:</Text>
          <ul>
            {nextStepsPoints.map(text => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </Constraint>
        <br />
        <FlexGrid>
          <FlexGrid.Column tablet={6} desktop={3}>
            <Button
              as="a"
              href={`//${consumerHost}/account/savings`}
              primary
              fluid
            >
              Okay
            </Button>
          </FlexGrid.Column>
        </FlexGrid>
      </Panel>
    </Container>
  );
}

SwitchSuccessful.propTypes = {
  consumerHost: PropTypes.string,
};

SwitchSuccessful.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
