import AccountStatus from '../../constants/accountStatus';
import ReviewAndSubmitForm from '../../components/ReviewAndSubmitForm';
import CheckingAccountForm from '../../components/CheckingAccountForm';
import CheckingAccountSummary from '../../components/CheckingAccountSummary';
import CustomerDetailsForm from '../../components/CustomerDetailsForm';
import CustomerDetailsSummary from '../../components/CustomerDetailsSummary';
import ShippingAddressForm from '../../components/ShippingAddressForm';
import ShippingAddressSummary from '../../components/ShippingAddressSummary';
import Enrolled from '../../components/Enrolled';
import MicrodepositForm from '../../components/MicrodepositForm';
import CardActivationForm from '../../components/CardActivationForm';
import CardActivationComplete from '../../components/CardActivationComplete';
import IntermediaryEnrollment from '../../components/IntermediaryEnrollment';
import BankVerified from '../../components/BankVerified';
import PromoEntry from '../../components/PromoEntry';
import getBankAccount from '../../../lib/utils/getBankAccount';
import WelcomeForm from '../../components/WelcomeForm';
import LoginForm from '../../components/LoginForm';
import MembershipUpgrade from '../../components/MembershipUpgrade';
import UpgradeSuccess from '../../components/UpgradeSuccess';
import UnderMaintenance from '../../components/UnderMaintenance';
import SavingsPage from '../../components/SavingsPage';
// import PremiumPanel from '../../components/PremiumPanel';
import GasBackPanel from '../../components/GasBackPanel';
import hasUsedIav from '../../../lib/utils/hasUsedIav';
import EnrollmentSteps from '../../constants/enrollmentSteps';
import { hasPayCard } from '../../../lib/utils/cardInstruments';
import isUnavailable from '../../../lib/utils/isUnavailable';
import MembershipBilling from '../../components/MembershipBilling';
import MembershipBillingSummary from '../../components/MembershipBillingSummary';
import PaymentOptions from '../../components/PaymentOptions';
import PaymentOptionsSummary from '../../components/PaymentOptionsSummary';
import GetStarted from '../../components/GetStarted';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

function hasBankAccount(instruments = []) {
  const BANK_ACCOUNT_TYPES = ['checking_account', 'savings_account'];
  const isBankAccount = instrument => BANK_ACCOUNT_TYPES.includes(instrument.instrument_type);
  return instruments.some(isBankAccount);
}

function hasUsedManualBankAccountLinking(instruments = []) {
  const bankAccount = getBankAccount(instruments);
  return !!bankAccount?.tags?.includes('no-iav') || !!bankAccount?.extra_data?.tags?.includes('no-iav');
}

export default function getEnrollmentConfig(wallet = {}, options = {}) {
  const {
    failedIdentityVerification,
    completedBilling,
    wantsPlus,
    wantsPremium,
    hasPlus,
    hasPremium,
    showLogin,
    showPromoEntry,
    showUpgraded,
    showWelcome,
    hasPeriodicPaymentsSet,
    hasSubmittedIdvCheckBefore,
  } = options;
  const {
    account_status: accountStatus,
    features,
    instruments,
    status: walletStatus,
    ready_to_ship: readyToShip,
    transaction_count: transactionCount,
  } = wallet;

  const isUnderMaintenance = features?.features?.find(f => f.n === 'enrollment_config')?.v === 'm';
  const wantsMembership = wantsPlus || wantsPremium;
  const usedIav = hasUsedIav(instruments);
  const hasFinishedEnrolment = accountStatus === AccountStatus.ReadyToTransact || hasPayCard(instruments);
  const usedManualBankAccountLinking = hasUsedManualBankAccountLinking(instruments);
  const isWaiting = (usedIav && accountStatus === AccountStatus.WaitingForSecondaryVerification)
    || (usedManualBankAccountLinking && accountStatus === AccountStatus.WaitingForMicrodeposits);
  const missingCustomerDetails = !walletStatus?.annual_income || !walletStatus?.license;
  const hasEsignedBefore = !!walletStatus?.esign;

  const steps = [];

  steps.push({
    id: 'maintenance',
    component: UnderMaintenance,
    gradient: true,
    requiresAttention: isUnderMaintenance,
  });

  if (showPromoEntry) {
    steps.push({
      id: 'promo',
      progressBar: 'enrollment',
      requiresAttention: showPromoEntry,
      component: PromoEntry,
    });
  }

  if (showWelcome) {
    steps.push({
      id: 'welcome',
      requiresAttention: showWelcome,
      component: WelcomeForm,
      hideFootnote: true,
    });
  }

  if (showLogin) {
    steps.push({
      id: 'login',
      requiresAttention: showLogin,
      component: LoginForm,
    });
  }

  steps.push(
    {
      id: EnrollmentSteps.Esign,
      title: showWelcome ? 'Let\'s get started!' : `Let's get started!${!wantsMembership ? ' It\'s free.' : ''}`,
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: !hasFinishedEnrolment && !hasEsignedBefore,
      component: GetStarted,
      editable: true,
    },
    {
      id: EnrollmentSteps.CustomerDetails,
      title: 'Customer Details',
      label: 'Customer Details',
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: hasEsignedBefore && missingCustomerDetails,
      component: CustomerDetailsForm,
      summary: CustomerDetailsSummary,
      editable: true,
    },
    {
      id: EnrollmentSteps.ShippingAddress,
      title: 'Shipping address',
      label: 'Shipping address',
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: !accountStatus || isUnavailable(walletStatus?.shipping_address),
      component: ShippingAddressForm,
      subheader: 'You are steps away from unblocking major savings on every gallon. We just need a few more things from you before we can ship your Pay with GasBuddy+™ card:',
      summary: ShippingAddressSummary,
      analyticsScreenName: ANALYTICS_SCREENS.PAYPLUS_ENROLL_SHIPPING,
      editable: true,
    },
    {
      id: EnrollmentSteps.BankAccount,
      title: 'Securely link your checking account',
      label: 'Checking Account',
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: !hasBankAccount(instruments),
      component: CheckingAccountForm,
      summary: CheckingAccountSummary,
      editable: true,
    },
  );

  steps.push({
    id: EnrollmentSteps.Billing,
    title: wantsMembership ? 'Payment Options' : 'ACH Payment Options',
    label: 'Payment Options',
    group: 'enroll',
    progressBar: 'enrollment',
    requiresAttention: !hasFinishedEnrolment
      && (
        (wantsMembership && !completedBilling)
        || (!wantsMembership && !hasPeriodicPaymentsSet)
      ),
    component: wantsMembership ? MembershipBilling : PaymentOptions,
    summary: wantsMembership ? MembershipBillingSummary : PaymentOptionsSummary,
    editable: true,
  });

  steps.push(
    {
      id: EnrollmentSteps.Review,
      title: `Review & ${wantsMembership ? 'purchase' : 'submit your information'}`,
      label: `Review & ${wantsMembership ? 'Purchase' : 'Submit'}`,
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: accountStatus === AccountStatus.PartiallyEnrolled
        && !hasFinishedEnrolment
        && (!hasSubmittedIdvCheckBefore || (hasSubmittedIdvCheckBefore && failedIdentityVerification === true))
        && (hasPeriodicPaymentsSet || completedBilling),
      component: ReviewAndSubmitForm,
      editable: true,
      hideFootnote: true,
    },
    {
      id: 'upgrade',
      title: `GasBuddy+™ ${wantsPlus ? 'Plus' : 'Premium'} Membership`,
      requiresAttention: hasFinishedEnrolment && ((wantsPlus && !hasPlus && !hasPremium) || (wantsPremium && !hasPremium)),
      component: MembershipUpgrade,
    },
    {
      id: 'upgraded',
      requiresAttention: showUpgraded,
      component: UpgradeSuccess,
      extra: GasBackPanel,
    },
    {
      id: EnrollmentSteps.Enrolled,
      label: `Review & ${wantsMembership ? 'Purchase' : 'Submit'}`,
      group: 'enroll',
      progressBar: 'enrollment',
      requiresAttention: isWaiting,
      component: Enrolled,
      // TODO: This needs to be turned off for now for Fleetcor enrolment,
      // will be turned back on once Plus / Premium programs are available with Fleetcor
      // extra: hasPlus || hasPremium ? GasBackPanel : PremiumPanel,
      editable: false,
      hideFootnote: true,
    },
    {
      id: 'inprogress',
      label: 'In Progress',
      progressBar: 'activation',
    },
    {
      id: 'microdeposits',
      label: 'On the Way',
      progressBar: 'activation',
      requiresAttention: [
        AccountStatus.WaitingForMicrodepositConfirmation,
        AccountStatus.WaitingForMicrodepositConfirmationWithOutstandingBalance,
      ].includes(accountStatus),
      component: MicrodepositForm,
    },
    {
      id: 'verified',
      progressBar: 'activation',
      requiresAttention: (
        (usedManualBankAccountLinking && accountStatus === AccountStatus.WaitingForSecondaryVerification)
        || (usedIav && [
          AccountStatus.WaitingForCardActivation,
          AccountStatus.WaitingForNewCardActivation,
        ].includes(accountStatus))
      ) && !readyToShip,
      component: BankVerified,
    },
    {
      id: 'activate',
      label: 'Activation',
      progressBar: 'activation',
      requiresAttention: [
        AccountStatus.WaitingForCardActivation,
        AccountStatus.WaitingForNewCardActivation,
      ].includes(accountStatus),
      component: CardActivationForm,
    },
    {
      id: 'activated',
      progressBar: 'activation',
      requiresAttention: accountStatus === AccountStatus.ReadyToTransact
        // Once user has finished driver id verification, card activation is shown looking at transactions
        // When enrolling with Plus or Premium membership, there would already be a transaction for fees
        // For free membership, there wont be any transactions
        && ((!wantsMembership && !transactionCount) || (wantsMembership && transactionCount === 1)),
      component: CardActivationComplete,
    },
    {
      id: 'done',
      requiresAttention: accountStatus === AccountStatus.ReadyToTransact,
      component: SavingsPage,
    },
    {
      id: 'unhandledState',
      requiresAttention: true,
      component: IntermediaryEnrollment,
    },
  );

  return steps;
}
