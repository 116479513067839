import { Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import GasBackExportList from '../GasBackExportList';
import GasBackTransferStep from '../GasBackTransferStep';

export default function GasBackTransferOptions({
  consumerHost,
  history,
  onSelectTransferOption,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAYPLUS_REDEEM_TYPES);

  const handleSelectTransferOption = useCallback((transferOption) => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_TYPES_SUBMIT_CLICKED, { type: transferOption.type });
    onSelectTransferOption(transferOption);
    history.push(transferOption.path);
  }, [history, onSelectTransferOption, trackEvent]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_REDEEM_TYPES_CANCEL_CLICKED);
  }, [trackEvent]);

  return (
    <GasBackTransferStep
      context={{ screen: ANALYTICS_SCREENS.PAYPLUS_REDEEM_TYPES }}
    >
      <Header as="h3" snug>Choose your transfer type</Header>
      <Text as="p">
        GasBuddies can transfer earned GasBack to any of the following options.
        Select your transfer type and confirm your withdrawal with just a few easy steps.
      </Text>
      <br />
      <GasBackExportList onSelectTransferOption={handleSelectTransferOption} />
      <GasBackTransferStep.Actions>
        <GasBackTransferStep.Button
          link
          as="a"
          href={`//${consumerHost}/account/savings`}
          onClick={handleCancel}
        >
          Cancel
        </GasBackTransferStep.Button>
      </GasBackTransferStep.Actions>
    </GasBackTransferStep>
  );
}

GasBackTransferOptions.propTypes = {
  consumerHost: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onSelectTransferOption: PropTypes.func,
};

GasBackTransferOptions.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  history: {
    push: () => {},
  },
  onSelectTransferOption: () => {},
};
