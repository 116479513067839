import { Constraint, Text, Actions } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import CardActivationHeader from '../CardActivationHeader';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

export default function MigratingCardActivationComplete({ consumerHost }) {
  useTracking(ANALYTICS_SCREENS.PAYPLUS_MIGRATE_ACTIVATION_SUCCESS);

  const message = 'Use your Pay with GasBuddy+™ card on fuel & qualifying c-store purchases at more stations nationwide, everywhere Mastercard® is accepted.';
  return (
    <React.Fragment>
      <CardActivationHeader title="Your Pay with GasBuddy+™ card has been activated!" />
      <br />
      <Constraint desktop={10}>
        <Text style={{ fontSize: '1.1rem' }}>{message}</Text>
        <br />
        <br />
        <br />
        <Actions>
          <Actions.Button
            as="a"
            href={`//${consumerHost}/home`}
            primary
            type="submit"
            tablet={6}
            desktop={5}
          >
            Find Stations
          </Actions.Button>
        </Actions>
      </Constraint>
    </React.Fragment>
  );
}

MigratingCardActivationComplete.propTypes = {
  consumerHost: PropTypes.string,
};

MigratingCardActivationComplete.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
