import { connect } from 'react-redux';
import ReviewAndSubmitForm from './ReviewAndSubmitForm';
import {
  fetchIavProviderDetails,
  shipCard,
  clearIdentityVerificationResult,
} from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ auth, config, instruments, membership, progress, wallet }) {
  const {
    canRetryIdentityVerification,
    failedIdentityVerification,
    hasSubmittedIdvCheckBefore,
    isShippingCard,
    cardShipError,
  } = instruments;
  const { email } = auth;
  const {
    offeringId,
    paymentOption,
  } = progress;
  const { stripeToken } = config;
  const { annual, monthly } = membership.premium;
  const selectedOffer = [annual, monthly].find(offer => offer.plan_offering_id === offeringId);
  const price = selectedOffer && selectedOffer.renewal_price;
  const periodType = selectedOffer && selectedOffer.period_type;
  const availableAddress = wallet.status?.shipping_address || {};
  const shippingAddress = {
    line_1: availableAddress.line_1,
    line_2: availableAddress.line_2,
    region: availableAddress.region,
    country: availableAddress.country,
    locality: availableAddress.locality,
    postal_code: availableAddress.postal_code,
  };

  return {
    canRetryIdentityVerification,
    consumerHost: config.consumerHost,
    isLoading: isShippingCard,
    email,
    error: getErrorMessage(cardShipError),
    failedIdentityVerification,
    hasSubmittedIdvCheck: hasSubmittedIdvCheckBefore,
    offeringId,
    periodType,
    price,
    stripeToken,
    paymentOption,
    shippingAddress,
    firstName: wallet.status?.name?.first_name,
    lastName: wallet.status?.name?.last_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      if (e) {
        e.preventDefault();
      }

      dispatch(shipCard(body));
    },
    fetchIavProviderDetails: () => dispatch(fetchIavProviderDetails()),
    clearIdentityVerificationResult: () => dispatch(clearIdentityVerificationResult()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAndSubmitForm);
