import { Action } from './actions';
import MigrationSteps from '../constants/migrationSteps';

const defaultState = {
  stepId: undefined,
  email: undefined,
  skipWelcome: false,
  esigned: false,
  postal: undefined,
  offeringId: undefined,
  switchingProgram: false,
  switchedProgram: false,
  paymentOption: undefined,
  isLoading: false,
  error: undefined,
  canSwitchPaymentOption: undefined,
  amountOwed: undefined,
  hasPendingBalance: undefined,
  statementNotYetGenerated: undefined,
  updatedPaymentOption: undefined,
};

export default function progressReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.Progress.GoToStep:
      return {
        ...state,
        stepId: action.payload,
      };

    case Action.ShippingAddress.UpdateCompleted:
    case Action.License.LicenseSaveCompleted:
    case Action.Profile.ProfileUpdateCompleted:
    case Action.Microdeposits.ConfirmCompleted:
    case Action.Activation.ActivateCardCompleted:
      return {
        ...state,
        stepId: undefined,
      };

    case Action.Activation.ActivateMigratingCardCompleted:
      return {
        ...state,
        stepId: MigrationSteps.Activated,
      };

    case Action.Bank.InstrumentCreateCompleted:
      return {
        ...state,
        editIndex: undefined,
        stepId: undefined,
      };

    case Action.Billing.SetOfferingId:
      return {
        ...state,
        offeringId: action.payload,
        stepId: undefined,
      };

    case Action.Billing.UpdatePaymentOptionPending:
    case Action.Billing.FetchCurrentPaymentOptionPending:
    case Action.Billing.UpdateCurrentPaymentOptionPending:
    case Action.Billing.SwitchToAutoPayOptionPending:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case Action.Billing.UpdatePaymentOptionCompleted:
    case Action.Billing.FetchCurrentPaymentOptionCompleted:
    case Action.Billing.UpdateCurrentPaymentOptionCompleted:
    case Action.Billing.SwitchToAutoPayOptionCompleted:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case Action.Billing.UpdatePaymentOptionFailed:
    case Action.Billing.FetchCurrentPaymentOptionFailed:
    case Action.Billing.UpdateCurrentPaymentOptionFailed:
    case Action.Billing.SwitchToAutoPayOptionFailed:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
