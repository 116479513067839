import { Actions, Constraint, Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import PayProgramContext from '../../context/payProgram';
import useTracking from '../../hooks/useTracking';
import DownloadApp from '../DownloadApp/DownloadApp';
import styles from './Enrolled.module.css';
import PwGBCardImage from '../PwGBCardImage';

const cx = classnames.bind(styles);

export default function Enrolled({
  hasRedirect,
  payHost,
  withIAV,
}) {
  const payProgram = useContext(PayProgramContext);
  const isPlus = payProgram === PayPrograms.Plus;
  const isPremium = payProgram === PayPrograms.Premium;

  useTracking(ANALYTICS_SCREENS.PAYPLUS_ENROLL_SUCCESS);

  let nextSteps = (
    <Fragment>
      {!withIAV && (
        <Fragment>
          <Text bold className={cx('topic')}>Verify small deposits</Text>
          <Text as="p">
            In 1-3 business days you will receive two small deposits in the account you linked.
            Once you receive the deposits, return to the GasBuddy app where you will be prompted to enter the two small deposit amounts.
            Once your account has been verified, the small deposits will be withdrawn from your account.
            Your card will arrive in 5-10 business days.
          </Text>
        </Fragment>
      )}
      <Text bold className={cx('topic')}>Activate your card</Text>
      <Text as="p">
        When your card arrives, visit your Savings Settings in the GasBuddy app where you will be prompted to activate your card.
        On the web, visit {payHost} and you’ll be prompted to activate your card.
      </Text>
    </Fragment>
  );

  if (isPlus || isPremium) {
    const cardPoints = [
      `When your card arrives, visit your Savings Settings in the GasBuddy app where you will be prompted to activate your card. On the web, visit ${payHost} and you’ll be prompted to activate your card.`,
    ];

    const perksPoints = [];

    if (isPremium) {
      perksPoints.push('Roadside service goes into effect 72 hours from your purchase date (today).');
    }

    perksPoints.push(
      'Your fuel discounts begin as soon as you receive and activate your card in the GasBuddy app.',
      'Your monthly gallon limit begins on the day you activate your card and restarts the same day the following month.',
    );

    const accountAndBillingPoints = [];

    if (withIAV) {
      accountAndBillingPoints.push('You will see a charge from GasBuddy on your account for your membership purchase. Your billing date will be the day you activate your card in the GasBuddy app.');
    } else {
      accountAndBillingPoints.push('In 1-3 business days you will receive two small deposits in the account you linked. Once you receive the deposits, return to the GasBuddy app where you will be prompted to enter the two small deposit amounts. Once your account has been verified, the small deposits will be withdrawn from your account. Your card will arrive in 5-10 business days.');
      accountAndBillingPoints.push('You will see a charge from GasBuddy on your account for your membership purpose. Your billing date will be the day you activate your card in the GasBuddy app.');
    }

    nextSteps = (
      <Fragment>
        <Text bold className={cx('topic')}>Activate your card</Text>
        <ul>
          {cardPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
        <Text bold className={cx('topic')}>Account and billing:</Text>
        <ul>
          {accountAndBillingPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
        <Text bold className={cx('topic')}>Fuel discounts{isPremium && ' & roadside assistance:'}</Text>
        <ul>
          {perksPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      </Fragment>
    );
  }

  const subtitle = (!withIAV || isPlus || isPremium)
    ? 'Your Pay with GasBuddy+™ card will arrive in 5-10 business days and then you’re just a few steps away from saving at stations nationwide.'
    : 'You’re just a few steps away from saving at stations nationwide. Complete the steps below to start saving with the Pay with GasBuddy+™ card.';

  return (
    <Constraint desktop={12}>
      <PwGBCardImage tilted />
      <Header as="h2" className={cx('title')}>
        You&apos;re now a {isPlus || isPremium ? `GasBuddy+™ ${payProgram} ` : 'Pay with GasBuddy+™ '}cardholder!
      </Header>
      <div className={cx('topContainer')}>
        <div className={cx('content')}>
          <Text
            as="p"
            className={cx('subtitle')}
            color="midnight"
          >
            {subtitle}
          </Text>
        </div>
      </div>
      <br />
      <div id="nextSteps">
        {nextSteps}
      </div>
      <br />
      <br />
      {hasRedirect ? (
        <Actions>
          <Actions.Button
            as="a"
            className={cx('cta')}
            fluid
            href="/action-completed/enrolled"
            primary
            tablet={6}
            desktop={6}
          >
            Continue
          </Actions.Button>
        </Actions>
      ) : (
        <DownloadApp />
      )}
    </Constraint>
  );
}

Enrolled.propTypes = {
  hasRedirect: PropTypes.bool,
  payHost: PropTypes.string,
  withIAV: PropTypes.bool,
};

Enrolled.defaultProps = {
  hasRedirect: false,
  payHost: 'pay.gasbuddy.com',
  withIAV: true,
};
