import React from 'react';
import { Text, Link, Checkbox } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './TermsOfService.module.css';
import LINKS from '../../constants/links';

const cx = classnames.bind(styles);

export default function TermsOfService({
  buttonText,
  onCheck,
}) {
  const handleTermsChecked = (e) => {
    if (onCheck) {
      onCheck(e.target.checked);
    }
  };

  return (
    <Checkbox
      id="termsAcceptance"
      name="acceptedTerms"
      onClick={handleTermsChecked}
      label={(
        <Text>
          By checking this box and clicking &quot;{buttonText}&quot; you are verifying that the information entered above is correct,
          and you are agreeing to GasBuddy’s&nbsp;
          <Link bold href={LINKS.GB_TERMS} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</Link>,&nbsp;
          <Link bold href={LINKS.GB_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>, and&nbsp;
          <Link bold href={LINKS.GB_CHARGE_CARD_AGREEMENT} target="_blank" rel="noopener noreferrer">GasBuddy Charge Card Agreement</Link>
          &nbsp;as well as our partner Dwolla&apos;s&nbsp;
          <Link bold href={LINKS.DWOLLA_TERMS} target="_blank" rel="noopener noreferrer">Terms of Service</Link>
          &nbsp;and&nbsp;
          <Link bold href={LINKS.DWOLLA_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
        </Text>
      )}
      className={cx('checkbox')}
    />
  );
}

TermsOfService.propTypes = {
  buttonText: PropTypes.string,
  onCheck: PropTypes.func,
};

TermsOfService.defaultProps = {
  buttonText: 'Submit',
  onCheck: undefined,
};
