import { Actions, Button, Constraint, Form, Header, Input, Link, Modal, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { Redirect } from 'react-router-dom';
import querystring from 'querystring';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import EnrollmentSteps from '../../constants/enrollmentSteps';
import PayProgramContext from '../../context/payProgram';
import useTracking from '../../hooks/useTracking';
import CheckingAccountSummary from '../CheckingAccountSummary';
import FormActionButtons from '../FormActionButtons';
import CustomerDetailsSummary from '../CustomerDetailsSummary';
import MembershipBillingSummary from '../MembershipBillingSummary';
import ShippingAddressSummary from '../ShippingAddressSummary';
import TermsOfService from '../TermsOfService';
import styles from './ReviewAndSubmitForm.module.css';
import sharedStyles from '../../styles/shared.module.css';
import PaymentOptionsSummary from '../PaymentOptionsSummary';
import { PaymentOptionPropType } from '../../prop-types/paymentOptions';
import AddressPropType from '../../prop-types/address';

const cx = classnames.bind({
  ...styles,
  ...sharedStyles,
});

const SummarySection = ({ title, children, onEdit }) => (
  <section className={cx('summary')}>
    <div className={cx('content')}>
      <Header as="h3">{title}</Header>
      {children}
    </div>
    <Link bold fluid onClick={onEdit} to="/enroll" uppercase>Edit</Link>
  </section>
);

SummarySection.propTypes = {
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default function ReviewAndSubmitForm({
  canRetryIdentityVerification,
  clearIdentityVerificationResult,
  consumerHost,
  email,
  error,
  failedIdentityVerification,
  fetchIavProviderDetails,
  firstName,
  goToStep,
  hasSubmittedIdvCheck,
  isLoading,
  lastName,
  offeringId,
  onSubmit,
  paymentOption,
  periodType,
  price,
  shippingAddress,
  stripeToken,
}) {
  const { trackEvent, trackPageView } = useTracking(ANALYTICS_SCREENS.PAYPLUS_ENROLL_CONFIRM);
  const payProgram = useContext(PayProgramContext);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [clientError, setClientError] = useState();
  const [shouldShowIdentityCheckFailure, setShouldShowIdentityCheckFailure] = useState(false);
  const enrollmentInfo = useMemo(() => ({
    firstName,
    lastName,
    shippingAddress,
    paymentOption,
  }), [firstName, lastName, shippingAddress, paymentOption]);
  const supportLink = `https://help.gasbuddy.com/hc/en-us/requests/new?${querystring.stringify({
    ticket_form_id: '23170679009943',
    tf_23167745567383: 'pay_with_gasbuddy_card_v2',
    tf_23168757126551: 'getting_started_v2',
  })}`;

  const handleTermsChecked = useCallback((isAccepted) => {
    setAcceptedTerms(isAccepted);
  }, []);

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_ENROLL_CONFIRM_SUBMIT_TAPPED, {
      AcceptedTerms: acceptedTerms,
    });

    if (acceptedTerms) {
      onSubmit(e, {
        acceptedTerms,
        offeringId,
        ...enrollmentInfo,
      });
    } else {
      e.preventDefault();
      setClientError('You must accept the terms of service');
    }
  }, [acceptedTerms, offeringId, onSubmit, trackEvent, enrollmentInfo]);

  const handleStripeModalOpened = useCallback(() => {
    trackPageView(ANALYTICS_SCREENS.PAYPLUS_PREMIUM_PURCHASE_CREDIT_CARD);
  }, [trackPageView]);

  const handleStripeComplete = useCallback((token) => {
    onSubmit(null, {
      acceptedTerms,
      offeringId,
      token,
      ...enrollmentInfo,
    });
  }, [acceptedTerms, offeringId, onSubmit, enrollmentInfo]);

  const handleEditClick = useCallback((e, step) => {
    e.preventDefault();
    trackEvent(ANALYTICS_EVENTS.PAYPLUS_ENROLL_EDIT_TAPPED, { SectionEdited: step });
    clearIdentityVerificationResult();
    goToStep(step);
  }, [goToStep, trackEvent, clearIdentityVerificationResult]);

  const handleEditAddressClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.ShippingAddress);
  }, [handleEditClick]);

  const handleEditCustomerDetailsClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.CustomerDetails);
  }, [handleEditClick]);

  const handleEditBankClick = useCallback((e) => {
    fetchIavProviderDetails();
    handleEditClick(e, EnrollmentSteps.BankAccount);
  }, [handleEditClick, fetchIavProviderDetails]);

  const handleEditBillingClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.Billing);
  }, [handleEditClick]);

  const handleCloseIdentityVerificationModal = useCallback(() => {
    setShouldShowIdentityCheckFailure(false);
  }, []);

  const canSubmit = useMemo(() => !isLoading
    && acceptedTerms
    && (typeof canRetryIdentityVerification === 'undefined' || canRetryIdentityVerification),
  [isLoading, acceptedTerms, canRetryIdentityVerification]);

  useEffect(() => {
    // Strict value check on failedIdentityVerification is for a reason.
    // The idv failure modal needs to be presented only to users who failed the identity verification.
    // The preloadEnrollmentState middleware sets these values only in that case.
    // The modal should not be presented if the value is not set.
    if (!isLoading && hasSubmittedIdvCheck && failedIdentityVerification === true) {
      setShouldShowIdentityCheckFailure(true);
    }
  }, [isLoading, hasSubmittedIdvCheck, failedIdentityVerification]);

  let actions;

  if (offeringId && periodType === 'year') {
    actions = (
      <Actions>
        <Actions.Button
          disabled={!acceptedTerms || isLoading}
          fluid
          loading={isLoading}
          primary
          type="submit"
          tablet={5}
          desktop={4}
        >
          Charge Bank Account
        </Actions.Button>
        <Actions.Action tablet={5} desktop={4}>
          <StripeCheckout
            ComponentClass="aside"
            allowRememberMe
            name="GasBuddy Premium"
            description="1 Year Subscription"
            currency="USD"
            email={email}
            amount={parseFloat(price) * 100} // Stripe wants value in cents
            token={handleStripeComplete}
            stripeKey={stripeToken}
            opened={handleStripeModalOpened}
            disabled={!acceptedTerms || isLoading}
          >
            <Button
              disabled={!acceptedTerms || isLoading}
              secondary
              fluid
              loading={isLoading}
              type="button" // Prevent form submission if JS disabled b/c it will charge bank
              className={cx('payWithCreditCard')}
            >
              Pay With Credit Card
            </Button>
          </StripeCheckout>
        </Actions.Action>
      </Actions>
    );
  } else {
    actions = (
      <FormActionButtons
        primaryButtonText={offeringId ? 'Purchase' : 'Submit'}
        canSubmit={canSubmit}
      />
    );
  }

  const description = `Please review the information you provided carefully before completing your ${offeringId ? `GasBuddy+™ ${payProgram}` : 'Pay with GasBuddy+™ '} enrollment.`;

  let paymentOptionsSummary;
  if (offeringId !== undefined) {
    paymentOptionsSummary = (
      <SummarySection title="Payment Options" onEdit={handleEditBillingClick}>
        <MembershipBillingSummary />
      </SummarySection>
    );
  } else if (['autopay', 'manual'].includes(paymentOption)) {
    paymentOptionsSummary = (
      <SummarySection title="ACH Payment Options" onEdit={handleEditBillingClick}>
        <PaymentOptionsSummary />
      </SummarySection>
    );
  } else {
    return (
      <Redirect to="/enroll/billing" />
    );
  }

  return (
    <Form
      action="/ship"
      className={cx('reviewForm')}
      loading={isLoading}
      method="post"
      onSubmit={handleSubmit}
    >
      <Modal
        size="md"
        content={() => (
          <Fragment>
            <Header as="h3">Unable to verify identity</Header>
            <Text as="p">
              We were unable to verify your identity.
              &nbsp;
              {canRetryIdentityVerification
                ? 'Please review your information and try again.'
                : 'Please contact support for details.'
              }
            </Text>
            <br />
            <br />
            <Button
              secondary
              wide
              as="a"
              href={`//${consumerHost}/account/savings`}
              rel="noopener noreferrer"
            >
              Cancel
            </Button>
            {canRetryIdentityVerification ? (
              <Button
                primary
                wide
                onClick={handleCloseIdentityVerificationModal}
              >
                Try Again
              </Button>
            ) : (
              <Button
                primary
                wide
                as="a"
                href={supportLink}
                rel="noopener noreferrer"
              >
                Contact Support
              </Button>
            )}
          </Fragment>
        )}
        onClose={handleCloseIdentityVerificationModal}
        forceIsShowing={shouldShowIdentityCheckFailure}
        closeIcon={canRetryIdentityVerification}
        bgClickable={canRetryIdentityVerification}
      />
      <Input type="hidden" name="offeringId" value={offeringId} />
      <Input type="hidden" name="paymentOption" value={paymentOption} />
      <Constraint tablet={7}>
        <Text as="p" className={cx('subtitle')}>
          {description}
        </Text>
      </Constraint>
      {!!(clientError || error) && (
        <Text as="p" color="orange">
          {clientError || error}
        </Text>
      )}
      <SummarySection title="Customer Details" onEdit={handleEditCustomerDetailsClick}>
        <CustomerDetailsSummary />
      </SummarySection>
      <SummarySection title="Shipping address" onEdit={handleEditAddressClick}>
        <ShippingAddressSummary />
      </SummarySection>
      <SummarySection title="Securely link your checking account" onEdit={handleEditBankClick}>
        <CheckingAccountSummary />
      </SummarySection>
      {paymentOptionsSummary}
      <Constraint className={cx('termsContainer')} tablet={9}>
        <TermsOfService onCheck={handleTermsChecked} />
      </Constraint>
      <Constraint desktop={10} className={cx('buttonsContainer')}>
        {actions}
      </Constraint>
    </Form>
  );
}

ReviewAndSubmitForm.propTypes = {
  canRetryIdentityVerification: PropTypes.bool,
  clearIdentityVerificationResult: PropTypes.func,
  consumerHost: PropTypes.string,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  failedIdentityVerification: PropTypes.bool,
  fetchIavProviderDetails: PropTypes.func,
  firstName: PropTypes.string.isRequired,
  goToStep: PropTypes.func,
  hasSubmittedIdvCheck: PropTypes.bool,
  isLoading: PropTypes.bool,
  lastName: PropTypes.string.isRequired,
  offeringId: PropTypes.number,
  onSubmit: PropTypes.func,
  paymentOption: PaymentOptionPropType,
  periodType: PropTypes.oneOf(['month', 'year']),
  price: PropTypes.string,
  shippingAddress: AddressPropType.isRequired,
  stripeToken: PropTypes.string.isRequired,
};

ReviewAndSubmitForm.defaultProps = {
  canRetryIdentityVerification: undefined,
  clearIdentityVerificationResult: () => {},
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  failedIdentityVerification: undefined,
  fetchIavProviderDetails: () => {},
  goToStep: () => {},
  hasSubmittedIdvCheck: false,
  isLoading: false,
  offeringId: undefined,
  onSubmit: () => {},
  paymentOption: undefined,
  periodType: undefined,
  price: undefined,
};
