import GoogleTagManager from '../../lib/utils/gtm';
import { ANALYTICS_EVENTS } from '../constants/analytics';
import SOCIAL_NETWORKS from '../constants/socialNetworks';
import { Action } from './actions';

export const defaultState = {
  isLoggingIn: false,
  error: undefined,
  signupError: undefined,
  appliedReferralCode: false,
  isApplyingReferralCode: false,
  referralCodeError: undefined,
};

const SocialNetworkNames = {
  [SOCIAL_NETWORKS.FACEBOOK]: 'facebook',
  [SOCIAL_NETWORKS.GOOGLE]: 'google',
};

function handleUserRegistered({ account_id: accountId, analytics, membername, method }) {
  if (analytics) {
    analytics.identifyUser({ id: membername });
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.REGISTERED,
      attributes: {
        Domain: 'payment-web',
        Method: method,
        Username: 'Default_Assigned',
      },
    });
  }

  GoogleTagManager.event({
    event: 'SignupComplete',
    domain: 'payment-web',
    method,
    userID: accountId,
  });

  GoogleTagManager.identifyUser(accountId);
}

function handleUserLoggedIn({ account_id: accountId, analytics, membername, method }) {
  if (analytics) {
    analytics.identifyUser({ id: membername });
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.LOGGED_IN,
      attributes: {
        Domain: 'payment-web',
        Method: method,
      },
    });
  }

  GoogleTagManager.event({
    event: 'LoginComplete',
    domain: 'payment-web',
    method,
    userID: accountId,
  });

  GoogleTagManager.identifyUser(accountId);
}

export default function authReducer(state = defaultState, action = {}) {
  const { meta, payload } = action;

  switch (action.type) {
    case Action.Auth.LoginPending:
    case Action.Auth.LoginCodePending:
      return {
        ...state,
        isLoggingIn: true,
      };

    case Action.Auth.LoginFailed:
    case Action.Auth.LoginCodeFailed:
      return {
        ...state,
        isLoggingIn: false,
        error: payload,
      };

    case Action.Auth.SocialAuthPending:
    case Action.Auth.SignupPending:
      return {
        ...state,
        isSigningUp: true,
        signupError: undefined,
      };

    case Action.Auth.SocialAuthFailed:
    case Action.Auth.SignupFailed:
      return {
        ...state,
        isSigningUp: false,
        signupError: 'Sorry, we could not log you in.',
      };

    // TODO? Should we be triggering a login event for this too?
    case Action.Auth.LoginCodeCompleted:
      window.location = '/enroll';
      return {
        isLoggingIn: false,
      };

    case Action.Auth.SocialAuthCompleted:
    case Action.Auth.LoginCompleted:
    case Action.Auth.SignupCompleted:
      // Redirect the user if login successful
      if (!payload.code) { // Login was successful if no code returned
        if (action.type === Action.Auth.LoginCompleted) {
          const method = SocialNetworkNames[payload.response.socialNetwork];
          handleUserLoggedIn({
            account_id: payload.response.accountId,
            analytics: meta.analyticsContext,
            membername: payload.response.memberName,
            method,
          });
        }

        if (action.type === Action.Auth.SocialAuthCompleted) {
          const method = SocialNetworkNames[payload.socialNetwork];
          handleUserLoggedIn({
            account_id: payload.accountId,
            analytics: meta.analyticsContext,
            membername: payload.memberName,
            method,
          });
        }

        if (action.type === Action.Auth.SignupCompleted) {
          const method = SocialNetworkNames[payload.response.socialNetwork];
          handleUserRegistered({
            account_id: payload.response.accountId,
            analytics: meta.analyticsContext,
            membername: payload.response.memberName,
            method,
          });
        }

        // TODO Fix these inconsistencies in identity-web
        window.location = payload.destination || payload.response.destination || '/';
        return {
          ...state,
          isLoggingIn: false,
          isSigningUp: false,
        };
      }

      return {
        ...state,
        error: payload,
        isLoggingIn: false,
        isSigningUp: false,
        signupError: 'Sorry, we could not log you in.',
      };

    case Action.Referral.ClaimReferral:
      return {
        ...state,
        isApplyingReferralCode: true,
        referralCodeError: undefined,
      };

    case Action.Referral.ClaimReferralComplete:
      return {
        ...state,
        appliedReferralCode: true,
        isApplyingReferralCode: false,
        referralCodeError: undefined,
      };

    case Action.Referral.ClaimReferralFailed:
      return {
        ...state,
        appliedReferralCode: false,
        isApplyingReferralCode: false,
        referralCodeError: action.payload,
      };

    default:
      return state;
  }
}
